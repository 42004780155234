import { DataTableEntity } from 'components/DataTable/DataTable.types';
import { DataTableColumnHeaderProps } from './DataTableColumnHeader.types';
import { useTranslate } from 'hooks/useTranslate';
import { DataTableColumnHeaderFilter } from './components/DataTableColumnHeaderFilter/DataTableColumnHeaderFilter';
import { styled } from 'styled-components';

const ThWrapperWhenNotUsingFiltering = styled.div`
  padding-left: 1rem;
`;

export function DataTableColumnHeader<T extends DataTableEntity>({
  column,
  withFiltering,
  withSelecting,
}: DataTableColumnHeaderProps<T>) {
  const t = useTranslate();

  const isCheckboxColumn: boolean = column.columnId === 'data-table-checkbox';
  const shouldShowFiltering: boolean = withFiltering && !isCheckboxColumn;

  if (shouldShowFiltering) {
    return <DataTableColumnHeaderFilter column={column} />;
  }

  // If this column contains checkboxes, we do not want to render a column header.
  if (isCheckboxColumn) {
    return null;
  }

  return <ThWrapperWhenNotUsingFiltering>{t(column.columnId)}</ThWrapperWhenNotUsingFiltering>;
}
