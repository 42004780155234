import { appConfig } from 'app/app.config';
import { configuredAxios } from './configuredAxios';
import {
  GetManyNCAsRequest,
  GetManyNCAsResponse,
  GetOneNCARequest,
  GetOneNCAResponse,
  ManyNCAsProcessingRequestSchema,
  NCAProcessingRequestSchema,
  UpdateManyNCAsResponse,
  UpdateWorkNotesDtoSchema,
} from './genTypes/dto';
import { AxiosResponse } from 'axios';

export const ncaAPI = {
  fetchMany: (params: URLSearchParams) => {
    return configuredAxios
      .get<GetManyNCAsRequest, AxiosResponse<GetManyNCAsResponse>>(appConfig.ncaEndpoint, {
        params: params,
      })
      .then((result) => result.data);
  },
  fetchOne: (r: GetOneNCARequest) => {
    return configuredAxios
      .get<GetOneNCARequest, AxiosResponse<GetOneNCAResponse>>(
        `${appConfig.ncaEndpoint}/${r.nonConformityAdviceId}`
      )
      .then((result) => result.data);
  },
  updateOneNCA: (request: NCAProcessingRequestSchema) => {
    return configuredAxios
      .post<NCAProcessingRequestSchema, AxiosResponse<void>>(appConfig.ncaEndpoint, request)
      .then((result) => result.data);
  },
  updateManyNCAs: (request: ManyNCAsProcessingRequestSchema) => {
    return configuredAxios
      .post<ManyNCAsProcessingRequestSchema, AxiosResponse<UpdateManyNCAsResponse>>(
        appConfig.updateManyNCAEndpoint,
        request
      )
      .then((result) => result.data);
  },
  updateWorkNotes: (request: UpdateWorkNotesDtoSchema) => {
    return configuredAxios
      .post<UpdateWorkNotesDtoSchema, AxiosResponse<void>>(appConfig.workNotesEndpoint, request)
      .then((result) => result.data);
  },
};
