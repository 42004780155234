import Search from '@ingka/search';
import './AppHeader.css';
import { AppHeaderUserInfo } from './components/AppHeaderUserInfo';
import { AppHeaderTitle } from './components/AppHeaderTitle';
import { AppHeaderLogo } from './components/AppHeaderLogo';

export function AppHeader() {
  return (
    <header className="app-header">
      <div className="app-header__section">
        <AppHeaderLogo />
        <AppHeaderTitle />
        <Search id="search" disabled />
      </div>

      <div className="app-header__section">
        <AppHeaderUserInfo />
      </div>
    </header>
  );
}
