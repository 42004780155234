import { useNCAControls } from '../hooks/useNCAControls';
import { NCAControlsSingleSelectProps } from '../NCAControls.types';
import { Row } from 'components/layout';
import { UnexpectedError } from 'components/alerts';
import { NCAControlsResolutionComment } from '../components/NCAControlsResolutionComment/NCAControlsResolutionComment';
import { NCAControlsActionSelect } from '../components/NCAControlsActionSelect/NCAControlsActionSelect';

/** Provides the controls needed to handle a single claim (i.e.: accept, reject, or send to LDM, etc.). */
export function NCAControlsSingleSelect({ nca }: NCAControlsSingleSelectProps) {
  const {
    isAnyClaimActionPossible,
    actionState,
    reasonCodeState,
    causingPartyState,
    resolutionCommentState,
    submittingState,
  } = useNCAControls([nca]);

  if (!nca) {
    return <UnexpectedError />;
  }

  return (
    <>
      {/* NCA Claim Controls */}
      <Row>
        {/* Resolution Comment */}
        <NCAControlsResolutionComment
          resolutionCommentState={resolutionCommentState}
          submittingState={submittingState}
        />

        {/* Claim Action */}
        <NCAControlsActionSelect
          isAnyClaimActionPossible={isAnyClaimActionPossible}
          actionState={actionState}
          reasonCodeState={reasonCodeState}
          causingPartyState={causingPartyState}
          submittingState={submittingState}
          messages={nca.messages}
          ncas={[nca]}
        />
      </Row>
    </>
  );
}
