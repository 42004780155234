import Badge from '@ingka/badge';
import Checkbox from '@ingka/checkbox';
import Tooltip from '@ingka/tooltip';
import { Link } from 'react-router-dom';
import { DataTableEntity } from '../../DataTable.types';
import { DataTableCellProps } from './DataTableCell.types';
import { ChangeEvent } from 'react';
import Button from '@ingka/button';
import pencil from '@ingka/ssr-icon/paths/pencil';
import trashCan from '@ingka/ssr-icon/paths/trash-can';

export function DataTableCell<T extends DataTableEntity>({
  column,
  idField,
  dataItem: entity,
  isTable,
  isSelected,
  isSelectable,
  onSelect,
  onEdit,
  onDelete,
}: DataTableCellProps<T>) {
  const handleOnSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (onSelect && idField) {
      onSelect({
        id: entity[idField],
        item: entity,
        checked: e.target.checked,
      });
    }
  };

  const renderValue = (value: any): string => {
    if (!value) {
      return undefined;
    }

    const text: string = String(value);

    // If isTable is true, we cut off the text to keep the table cell content a reasonable size.
    if (isTable) {
      return text.slice(0, 50) + (text.length > 50 ? '...' : '');
    }

    return text;
  };

  // Render nothing if the dataItem is not defined.
  if (!entity) {
    return null;
  }

  // TODO: Update disable/enabled checkbox styles to be more obvious
  const selectBox = (isSelected: boolean, isSelectable: boolean) => (
    <Checkbox
      id={`data-table-checkbox`}
      data-testid="data-table-checkbox"
      value={''}
      checked={isSelected}
      onChange={handleOnSelect}
      disabled={!isSelected && !isSelectable}
    />
  );

  // Render a checkbox if this is a checkbox column
  if (column.columnId === 'data-table-checkbox' && column.columnType === 'checkbox') {
    if (isSelected || isSelectable) {
      return selectBox(isSelected, isSelectable);
    }
    return (
      <Tooltip
        // TODO: Update to more specific description
        tooltipText={'This claim cannot be selected'}
        position={'trailing'}
        children={selectBox(isSelected, isSelectable)}
        data-testid="disabled-testid"
      />
    );
  }

  if (column.columnType === 'edit') {
    return <Button iconOnly size="small" onClick={() => onEdit(entity.id)} ssrIcon={pencil} />;
  }

  if (column.columnType === 'delete') {
    return <Button iconOnly size="small" onClick={() => onDelete(entity.id)} ssrIcon={trashCan} />;
  }

  const value = entity[column.columnId];

  if (column.columnType === 'boolean') {
    return <input type="checkbox" checked={Boolean(value)} disabled />;
  }

  if (column.columnType === 'date' && value) {
    const dateFormatRegex: RegExp =
      /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}):\d{2}\.\d{3}([+-]\d{2}:\d{2})$/;
    const dateFormatMatch = renderValue(value).match(dateFormatRegex);

    if (dateFormatMatch) {
      const [, datePart, timePart, timezonePart] = dateFormatMatch;

      return <>{`${datePart} ${timePart} ${timezonePart}`}</>;
    }
  }

  if (column.columnType === 'link') {
    return <Link to={entity[column.columnId]}>{renderValue(entity[column.columnId])}</Link>;
  }

  if (column.columnType === 'select') {
    const option = column.options.find((o) => o.value === value);

    if (option) {
      return (
        <Badge label={option.label || option.value} colour={option.color || 'blue'} size="small" />
      );
    }
  }

  // If a render function is provided, use it to render the value.
  if (column.render) {
    return <>{column.render(entity)}</>;
  }

  // Lastly, render the value as a string.
  return <>{renderValue(entity[column.columnId])}</>;
}
