import { acquireAccessToken } from 'app/app.auth';
import { appConfig } from 'app/app.config';
import axios from 'axios';

const configuredAxios = axios.create();
configuredAxios.defaults.headers.post['Content-Type'] = 'application/json';

configuredAxios.interceptors.request.use(
  async (config) => {
    const token = await acquireAccessToken(appConfig.appScope.split(','));
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }

    // If the URL called is not an absolute URL, prepend the base API URL
    if (!config.url?.startsWith('http')) {
      config.url = `${appConfig.apiBaseUrl}/${appConfig.apiVersion}/${config.url}`;
    }

    return config;
  },
  (error) => {
    // TODO: Could add a call to toast to automatically notify failed call
    Promise.reject(error);
  }
);

export { configuredAxios };
