import { Subsection } from 'components/common/Subsection';
import { NCAListTable } from './components/NCAListTable/NCAListTable';
import { useNCAList } from './hooks/useNCAList';
import { NCAListFilter } from './components/NCAListFilter/NCAListFilter';
import { styled } from 'styled-components';
import { useNCAControlsMultiSelectStore } from '../NCAControlsMultiSelect/NCAControlsMultiSelect.store';
import { NCAControls } from '../NCAControls/NCAControls';
import { NCAListPagination } from './components/NCAListPagination/NCAListPagination';

const NCAListLayout = styled.div`
  height: 30rem;
  /* width: calc(100vw - 5rem - 1rem - 1rem); */
  display: grid;
  gap: 0.5rem;
  grid-template-columns: calc(100vw - 11rem - 4rem);
  grid-template-rows:
    5rem
    calc(100vh - 5rem - 2rem - 5rem - 5rem - 1rem)
    5rem;
  grid-template-areas:
    'nca-list-filter'
    'nca-list-table'
    'nca-list-pagination';

  &.with-multi-select {
    grid-template-rows:
      5rem
      calc(100vh - 5rem - 2rem - 5rem - 6rem - 5rem - 1rem)
      5rem
      6rem;

    grid-template-areas:
      'nca-list-filter'
      'nca-list-table'
      'nca-list-pagination'
      'nca-list-multi-select';
  }
`;

const NCAListSubsectionFilter = styled(Subsection)`
  grid-area: nca-list-filter;
`;

const NCAListSubsectionTable = styled(Subsection)`
  grid-area: nca-list-table;

  .ui-subsection__body {
    max-height: 100%;
    overflow-y: auto;
  }
`;

const NCAListSubsectionPagination = styled(Subsection)`
  grid-area: nca-list-pagination;
`;

const NCAListSubsectionMultiSelect = styled(Subsection)`
  grid-area: nca-list-multi-select;
  display: none;

  &.with-multi-select {
    display: block;
  }
`;

/** NCAList is a page that shows a filterable list of all NCAs. */
export function NCAList() {
  const { columns, data, totalNumberOfPages, isLoading, error } = useNCAList();
  const ncaControlsMultiSelectStore = useNCAControlsMultiSelectStore();
  const showMultiSelect: boolean = ncaControlsMultiSelectStore.selectedItems.size > 0;

  return (
    <NCAListLayout className={showMultiSelect ? 'with-multi-select' : ''}>
      <NCAListSubsectionFilter>
        <NCAListFilter />
      </NCAListSubsectionFilter>
      <NCAListSubsectionTable>
        <NCAListTable columns={columns} data={data} isLoading={isLoading} error={error} />
      </NCAListSubsectionTable>
      <NCAListSubsectionPagination>
        <NCAListPagination
          totalPages={totalNumberOfPages}
          viewCount={data ? data.length : 0}
          loading={isLoading}
        />
      </NCAListSubsectionPagination>
      <NCAListSubsectionMultiSelect className={showMultiSelect ? 'with-multi-select' : ''}>
        <NCAControls many />
      </NCAListSubsectionMultiSelect>
    </NCAListLayout>
  );
}
