import { configuredAxios } from './configuredAxios';
import { appConfig } from 'app/app.config';
import {
  AssignmentRuleMatrixDtoSchema,
  BackendForm,
  SubmitLdmParametersFormRequest,
  SubmitLdmParametersFormResponseBody,
  UpdateAssignmentRuleMatrixDtoSchema,
  UpdateAssignmentRuleMatrixResponseDtoSchema,
} from './genTypes/dto';

export const ldmAPI = {
  getLdmParametersForm: (): Promise<BackendForm> => {
    return configuredAxios
      .get<BackendForm>(appConfig.ldmParametersFormEndpoint)
      .then((response) => {
        return response.data;
      });
  },
  setParameters: (
    changedValues: SubmitLdmParametersFormRequest
  ): Promise<SubmitLdmParametersFormResponseBody> => {
    return configuredAxios
      .post<SubmitLdmParametersFormResponseBody>(appConfig.ldmParametersFormEndpoint, changedValues)
      .then((response) => {
        return response.data;
      });
  },
  getAssignments: () => {
    return configuredAxios
      .get<AssignmentRuleMatrixDtoSchema>(appConfig.asssignmentRulesEndpoint)
      .then((response) => {
        return response.data;
      });
  },
  setAssignments: (
    changedRules: UpdateAssignmentRuleMatrixDtoSchema
  ): Promise<UpdateAssignmentRuleMatrixResponseDtoSchema> => {
    return configuredAxios
      .post<UpdateAssignmentRuleMatrixResponseDtoSchema>(
        appConfig.asssignmentRulesEndpoint,
        changedRules
      )
      .then((response) => {
        return response.data;
      });
  },
};
