import { useSearchParams } from 'react-router-dom';
import { useNCAListQuery } from './useNCAListQuery/useNCAListQuery';
import { NCADto } from 'api/genTypes/dto';
import { useNCAListColumns } from './useNCAListColumns/useNCAListColumns';
import { DataTableColumn } from 'components/DataTable/DataTable.types';

/** useNCAList manages the logic of the NCA List page. */
export function useNCAList(): {
  data: NCADto[] | undefined;
  totalNumberOfPages: number;
  isLoading: boolean;
  error: unknown | undefined;
  columns: DataTableColumn<NCADto>[];
  refetch: () => void;
} {
  // Get the search parmas from the URL first. If any search params are applied,
  // we need to use those in the query to filter data.
  // These URL search params can be set from anywhere, so we do not actually need
  // to manage setting the search params here. This can be delegated to the filter component and hook.
  // As long as we subscribe to any changes here and apply them to the query we send to the API.
  const [searchParams] = useSearchParams();
  // Retrieve the columns to display
  const { columns } = useNCAListColumns();
  // Query data from the server.
  const { data, isLoading, error, refetch } = useNCAListQuery(searchParams);

  return {
    data: data?.ncaList,
    totalNumberOfPages: data?.totalNumberOfPages || 1,
    isLoading,
    error,
    columns,
    refetch,
  };
}
