import styled from 'styled-components';
import { NCAControlsProps } from './NCAControls.types';
import { NCAControlsSingleSelect } from './NCAControlsSingleSelect/NCAControlsSingleSelect';
import { NCAControlsMultiSelect } from '../NCAControlsMultiSelect/NCAControlsMultiSelect';
import { NCADto } from 'api/genTypes/dto';
import { UnexpectedError } from 'components/alerts';

export const NCAControlsLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  flex-grow: 1;
  align-items: stretch;
  gap: 1rem;
`;

export function NCAControls({ nca, many }: NCAControlsProps) {
  if (!nca && !many) {
    return <UnexpectedError />;
  }
  if (many) {
    return <NCAControlsMultiSelect />;
  }
  // Casting from GetOneNCAResponse to NCADto. Actions only use fields that are in both, and NCADto is a subset of GetOneNCAResponse
  return <NCAControlsSingleSelect nca={nca as NCADto} />;
}
