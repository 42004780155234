import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';

export function useSignOut(account: AccountInfo) {
  const { instance } = useMsal();
  const appInsights = useAppInsightsContext();
  const trackSignedOut = useTrackEvent(appInsights, 'Signed Out', {});
  // TODO: In order to skip account picker on logout, we should provide a logout hint. https://stackoverflow.com/questions/70456800/msal-logout-displaying-multiple-account
  const signOut = () =>
    instance
      .logout({
        account: account,
      })
      .then(() => trackSignedOut({}))
      .then(() => window.location.reload());

  return signOut;
}
