import { useMsal } from '@azure/msal-react';
import Button from '@ingka/button';
import { styled } from 'styled-components';
import Text from '@ingka/text';
import { Subsection } from 'components/common/Subsection';
import { useTranslate } from 'hooks/useTranslate';
import { useNavigate } from 'react-router-dom';
import { AppHeaderLogo } from '../AppHeader/components/AppHeaderLogo';
import { PopupRequest } from '@azure/msal-browser';
import { appConfig } from 'app/app.config';

export const loginRequest: PopupRequest = {
  scopes: appConfig.appScope.split(','),
};

const StyledUnauthenticatedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #0058a3;
`;
const StyledUnauthenticatedPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

export function useAppLogin() {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const navigateToLoginRedirect = () => {
    navigate('/');
    instance.loginRedirect(loginRequest);
  };

  return {
    /** Redirect to the SSO screen. */
    navigateToLoginRedirect,
  };
}

export const AppLogin = () => {
  const { navigateToLoginRedirect } = useAppLogin();
  const translate = useTranslate();

  return (
    <StyledUnauthenticatedContainer>
      <Subsection>
        <StyledUnauthenticatedPanel>
          <AppHeaderLogo />
          <Text headingSize="m">{translate('welcome')}</Text>
          <Text headingSize="xs">{translate('loginDescription')}</Text>
          <Button size="medium" type="secondary" onClick={navigateToLoginRedirect}>
            {translate('loginWith')}
          </Button>
        </StyledUnauthenticatedPanel>
      </Subsection>
    </StyledUnauthenticatedContainer>
  );
};
