// import { useNCAListQuery } from '../../NCAList/hooks/useNCAListQuery/useNCAListQuery';
import { DataTableColumn } from 'components/DataTable/DataTable.types';
import {
  UseNCAControlsMultiSelectStore,
  useNCAControlsMultiSelectStore,
} from '../NCAControlsMultiSelect.store';
import { NCADto, UpdateManyNCAsResponse } from 'api/genTypes/dto';
import { useDisclosure } from 'utils/hooks';

export function useNCAControlsMultiSelect() {
  const store: UseNCAControlsMultiSelectStore = useNCAControlsMultiSelectStore();

  // TODO: Refactor this to fetch a set of NCAs from the server.
  // const ncaList = useNCAListQuery(new URLSearchParams());
  // const allNCAs = ncaList.data?.ncaList || [];
  // const selectedNCAs = allNCAs.filter((nca) =>
  //   store.selectedIds.find((selectedId) => nca.nonConformityAdviceId === selectedId)
  // );

  // const nbrOfSelectedNCAs: number = store.selectedIds.length;
  // const sumOfAmountForSelectedNCAs: number = store.selectedItems.reduce(
  //   (previous, current) => previous + (current.amount || 0),
  //   0
  // );

  const selectedNCAsColumns: DataTableColumn<NCADto>[] = [
    {
      columnId: 'nonConformityAdviceId',
      columnType: 'string',
    },
    {
      columnId: 'amountEuro',
      columnType: 'number',
    },
    {
      columnId: 'flow',
      columnType: 'string',
    },
    {
      columnId: 'status',
      columnType: 'string',
    },
    {
      columnId: 'costType',
      columnType: 'string',
    },
    {
      columnId: 'tspId',
      columnType: 'string',
    },
  ];

  const overviewColumns: DataTableColumn<any>[] = [
    {
      columnId: 'ncaMultiSelectCount',
      columnType: 'number',
    },
    {
      columnId: 'ncaMultiSelectSum',
      columnType: 'number',
    },
  ];

  const isMultiSelectPanelVisible: boolean = store.nbrOfItems > 0;

  const deselectAll = () => store.setState({ items: new Map<string, NCADto>() });

  const disclosure = useDisclosure();

  const onNotificationSubmission = (data: UpdateManyNCAsResponse) => {
    if (data.failed.length > 0) {
      store.deselectSome([
        ...data.approved,
        ...data.rejected,
        ...data.sent_to_ldm,
        ...data.additional_info_requested,
      ]);
    } else {
      disclosure.close();
      deselectAll();
    }
  };

  return {
    ...store,
    selectedNCAs: store.selectedItems,
    nbrOfSelectedNCAs: store.nbrOfItems,
    sumOfAmountForSelectedNCAs: store.totalAmount,
    selectedNCAsColumns,
    overviewColumns,
    isMultiSelectPanelVisible,
    deselectAll,
    disclosure,
    onNotificationSubmission,
  };
}
