import FormField from '@ingka/form-field';
import TextArea from '@ingka/text-area';
import { useTranslate } from 'hooks/useTranslate';
import {
  NCAControlsStateForResolutionComment,
  NCAControlsStateForSubmitting,
} from '../../NCAControls.types';

export interface NCAControlsResolutionCommentProps {
  resolutionCommentState: NCAControlsStateForResolutionComment;
  submittingState: NCAControlsStateForSubmitting;
}

export function NCAControlsResolutionComment({
  resolutionCommentState,
  submittingState,
}: NCAControlsResolutionCommentProps) {
  const t = useTranslate();
  return (
    <FormField
      characterLimit={500}
      fieldHelper={{
        id: 'resolution-comment-helper-msg-id',
        msg: t('resolutionCommentHelper'),
        type: '',
      }}
      valid={!resolutionCommentState.showResolutionCommentError}
      validation={{
        id: 'resolution-comment-error-msg-id',
        msg: t('resolutionCommentError'),
        type: 'error',
      }}
      style={{ flexGrow: 1, minWidth: '50%', height: '100%' }}
      disabled={resolutionCommentState.isResolutionCommentDisabled || submittingState.isSubmitting}
      shouldValidate={resolutionCommentState.showResolutionCommentError}
    >
      <TextArea
        id="resolution-comment-input"
        label={t('resolutionComment')}
        style={{ height: '100%' }}
        value={resolutionCommentState.resolutionComment}
        onChange={(e) => resolutionCommentState.setResolutionComment(e.target.value)}
        readOnly={
          resolutionCommentState.isResolutionCommentDisabled || submittingState.isSubmitting
        }
      />
    </FormField>
  );
}
