import { configuredAxios } from './configuredAxios';
import { AxiosResponse } from 'axios';
import { BusinessUnitRequestSchema, BusinessUnitsSchema } from './genTypes/dto';
import { appConfig } from 'app/app.config';

export const businessUnitsAPI = {
  fetchMany: () => {
    return configuredAxios
      .get<BusinessUnitRequestSchema, AxiosResponse<BusinessUnitsSchema>>(
        appConfig.businessUnitsEndpoint,
        {}
      )
      .then((result) => result.data);
  },
};
