import { MsalProvider } from '@azure/msal-react';
import {
  AppInsightsErrorBoundary,
  AppInsightsContext,
} from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'appInsights';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactNode } from 'react';
import { appConfig } from 'app/app.config';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { AppNotificationsProvider } from './app.notifications';

export const msalConfiguration: Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${appConfig.appTenantId}`,
    clientId: appConfig.appClientId,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const msalInstance = new PublicClientApplication(msalConfiguration);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Stale queries are refetched automatically in the background when:
      // - New instances of the query mount
      // - The window is refocused
      // - The network is reconnected
      // - The query is optionally configured with a refetch interval
      //
      // Specifying a longer staleTime means queries will not refetch their data as often.
      // If you see a refetch that you are not expecting, it is likely because you just focused the window and TanStack Query is doing a refetchOnWindowFocus.
      // During development, this will probably be triggered more frequently, especially because focusing between the Browser DevTools and your app will also cause a fetch, so be aware of that.
      //
      // Source: https://tanstack.com/query/latest/docs/framework/react/guides/important-defaults
      //
      // Current default value is: 20 seconds. Which means a query will only refetch every 20 seconds, UNLESS we invalidate it explicitly via some action or change our query params.
      staleTime: appConfig.staleTime,
    },
  },
});

export function AppProviders({ children }: { children: ReactNode }) {
  return (
    <MsalProvider instance={msalInstance}>
      <AppInsightsErrorBoundary
        onError={() => <h1>Something went wrong</h1>}
        appInsights={reactPlugin}
      >
        <AppInsightsContext.Provider value={reactPlugin}>
          <QueryClientProvider client={queryClient}>
            <AppNotificationsProvider>{children}</AppNotificationsProvider>
          </QueryClientProvider>
        </AppInsightsContext.Provider>
      </AppInsightsErrorBoundary>
    </MsalProvider>
  );
}
