import { useState } from 'react';
import { NCAWorkNotesDto } from 'api/genTypes/dto';
import { ncaAPI } from 'api/ncaAPI';
import { useNcaDetails } from '../../hooks/useNcaDetails';
import { useAppNotifications } from 'app/app.notifications';
import { UseNCAWorkCommentResult } from './NCAWorkCommentTypes';
import { useTranslate } from 'hooks/useTranslate';

export function handleSubmit(
  comment: string,
  ncaId: string,
  notifications: {
    showSuccessNotification: (msg: string) => void;
    showErrorNotification: (msg: string) => void;
  },
  setIsSubmitting: (loading: boolean) => void,
  setErrorMessage: (message: string) => void,
  resetState: () => void,
  refetch: () => void,
  t: ReturnType<typeof useTranslate>
): Promise<void> {
  setIsSubmitting(true);

  return ncaAPI
    .updateWorkNotes({
      nonConformityAdviceId: ncaId,
      comment: comment,
    })
    .then(() => {
      notifications.showSuccessNotification(t('updateWorkCommentSuccess'));
      resetState();
      refetch();
    })
    .catch((e) => {
      notifications.showErrorNotification(t('updateWorkCommentError'));
      setErrorMessage(t('workCommentError'));
    })
    .finally(() => {
      setIsSubmitting(false);
    });
}

export function resetState(
  setWorkCommentError: (error: string) => void,
  setHasChanges: (hasChanges: boolean) => void
) {
  setWorkCommentError(null);
  setHasChanges(false);
}

export function useNCAWorkComment(
  ncaId: string,
  comment?: NCAWorkNotesDto
): UseNCAWorkCommentResult {
  const [workCommentError, setWorkCommentError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [workComment, setWorkComment] = useState(comment ? comment.comment : '');
  const [hasChanges, setHasChanges] = useState(false);
  const notifications = useAppNotifications();
  const { refetch } = useNcaDetails();
  const t = useTranslate();

  return {
    workComment,
    setWorkComment: (input: string) => {
      setHasChanges(true);
      setWorkComment(input);
    },
    hasChanges: hasChanges,
    workCommentError,
    isSubmitting,
    submit: async () =>
      handleSubmit(
        workComment,
        ncaId,
        notifications,
        setIsSubmitting,
        setWorkCommentError,
        () => resetState(setWorkCommentError, setHasChanges),
        refetch,
        t
      ),
  };
}
