//Deploy to feature #1
import { API_QUERY_STALE_TIME } from 'app/app.constants';
import { getTemplatedAppNameOrDefault } from 'utils/getTemplatedAppNameOrDefault';

declare var window: any;

function getEnvOrDefault<T = string | number | undefined>(envVar: string, defaultValue?: T): T {
  return window?.env?.[envVar] || process.env[`REACT_APP_${envVar}`] || defaultValue;
}

export const appConfig = {
  appName: getTemplatedAppNameOrDefault('Extra Cost Handling'),
  apiBaseUrl: getEnvOrDefault<string>('BASE_URL', ''),
  apiVersion: getEnvOrDefault<string>('API_VERSION', 'ech-api/v1'),
  customerEndpoint: getEnvOrDefault<string>('CUSTOMER_BACKEND_ENDPOINT', 'customer'),
  ncaEndpoint: getEnvOrDefault<string>('API_ENDPOINT_NCA', 'nca'),
  workNotesEndpoint: getEnvOrDefault<string>('API_ENDPOINT_WORKNOTES', 'nca/workNotes'),
  attachmentsEndpoint: getEnvOrDefault<string>('API_ENDPOINT_ATTACHMENTS', 'attachments'),
  updateManyNCAEndpoint: getEnvOrDefault<string>('UPDATE_MANY_NCA_ENDPOINT', 'manyNcas'),
  configurationsEndpoint: getEnvOrDefault<string>('API_ENDPOINT_CONFIGURATIONS', 'configurations'),
  businessUnitsEndpoint: getEnvOrDefault<string>('API_ENDPOINT_BUSINESS_UNITS', 'businessUnits'),
  ldmParametersFormEndpoint: getEnvOrDefault<string>(
    'API_ENDPOINT_LDM_PARAMETERS_FORM',
    'ldm/parameters'
  ),
  asssignmentRulesEndpoint: getEnvOrDefault<string>(
    'API_ENDPOINT_LDM_ASSIGNMENT_RULES',
    'ldm/assignments/rules'
  ),
  appClientId: getEnvOrDefault<string>('CLIENT_ID'),
  appTenantId: getEnvOrDefault<string>('TENANT_ID'),
  appScope: getEnvOrDefault<string>('SCOPE'),
  appInsightKey: getEnvOrDefault<string>('INSIGHTS_KEY'),
  version: 'v0.22',
  staleTime: getEnvOrDefault<number>('STALE_TIME', API_QUERY_STALE_TIME),
};

Object.entries(appConfig).forEach(([key, value]) => {
  if (value === null || value === '' || value === undefined) {
    throw Error(`Missing value for env variable ${key} in env-config`);
  }
});
