import create from 'zustand';
import skapaToken from '@ingka/variables';
import Toast from '@ingka/toast';
import { TOAST_TIMEOUT } from './app.constants';
import { useTranslate } from 'hooks/useTranslate';

type AppNotificationType = 'info' | 'success' | 'warning' | 'error';

export interface AppNotification {
  type: AppNotificationType;
  message: string;
}

interface AppNotificationsStore {
  notifications: AppNotification[];
  showNotification: (notification: AppNotification) => void;
  showInfoNotification: (message: string) => void;
  showSuccessNotification: (message: string) => void;
  showWarningNotification: (message: string) => void;
  showErrorNotification: (message: string) => void;
  closeNotification: (notification: AppNotification) => void;
}

export const useAppNotifications = create<AppNotificationsStore>((set, get) => ({
  notifications: [],
  showNotification: (notification: AppNotification) => {
    // Add the notification to the list of notifications
    set((state) => ({
      notifications: [...state.notifications, notification],
    }));

    // After a certain timeout, remove the notification
    setTimeout(() => {
      set((state) => ({
        notifications: state.notifications.filter((n) => n !== notification),
      }));
    }, TOAST_TIMEOUT);
  },
  showInfoNotification: (message: string) => {
    get().showNotification({ type: 'info', message });
  },
  showSuccessNotification: (message: string) => {
    get().showNotification({ type: 'success', message });
  },
  showWarningNotification: (message: string) => {
    get().showNotification({ type: 'warning', message });
  },
  showErrorNotification: (message: string) => {
    get().showNotification({ type: 'error', message });
  },
  closeNotification: (notification: AppNotification) => {
    set((state) => ({
      notifications: state.notifications.filter((n) => n !== notification),
    }));
  },
}));

const bgColor: Record<AppNotificationType, string> = {
  info: undefined,
  success: skapaToken.colourSemanticPositive,
  warning: skapaToken.colourSemanticCaution,
  error: skapaToken.colourSemanticNegative,
};

export function AppNotificationsProvider({ children }: { children: React.ReactNode }) {
  const appNotifications = useAppNotifications();
  const t = useTranslate();

  return (
    <>
      {children}
      {appNotifications.notifications.map((notification, index) => (
        <Toast
          key={`notification-${index}-${notification.message}`}
          style={{
            backgroundColor: bgColor[notification.type],
            borderColor: bgColor[notification.type],
          }}
          text={notification.message}
          isOpen={true}
          onCloseRequest={() => appNotifications.closeNotification(notification)}
          ariaLabelCloseBtn={t('dismissNotification')}
          data-testid={notification.type + '-notification-testid'}
        />
      ))}
    </>
  );
}
