import { Stack } from 'components/layout';
import { BackendFormResult } from './BackendForm.types';
import { BackendFormControlField } from './BackendFormControlField';

export function BackendFormUI({ form }: { form: BackendFormResult }) {
  return (
    <Stack>
      {form.controls.map((control, i) => (
        <BackendFormControlField key={i} control={control} />
      ))}
    </Stack>
  );
}
