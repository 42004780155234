import { businessUnitsAPI } from 'api/businessUnitsApi';
import { BusinessUnitSchema, BusinessUnitsSchema } from 'api/genTypes/dto';
import { useQuery } from 'react-query';
import { API_INFREQUENT_QUERY_STALE_TIME } from 'app/app.constants';

export function useBusinessUnits() {
  return useQuery<BusinessUnitsSchema>({
    queryKey: [],
    queryFn: () => businessUnitsAPI.fetchMany(),
    staleTime: API_INFREQUENT_QUERY_STALE_TIME,
  });
}

export function getCausingParties(
  data?: BusinessUnitsSchema,
  isLoading?: boolean,
  error?: any
): BusinessUnitSchema[] {
  if (isLoading) {
    return [];
  }

  if (error) {
    return [];
  }

  if (!data || !data.items) {
    return [];
  }

  return data.items;
}

export function useCausingParties(): BusinessUnitSchema[] {
  const { data, isLoading, error } = useBusinessUnits();

  return getCausingParties(data, isLoading, error);
}
