import Avatar from '@ingka/avatar';
import { Dropdown } from 'components/common/Dropdown';
import { useTranslate } from 'hooks/useTranslate';
import styled from 'styled-components';
import Button from '@ingka/button';
import Text from '@ingka/text';
import { useCurrentUser } from 'app/app.auth';

const StyledUserInfoButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;

export function AppHeaderUserInfo() {
  // const { userInitials, userName, userNameFull, signOut } = useAccountInfo();
  const currentUser = useCurrentUser();
  const translate = useTranslate();

  return (
    <Dropdown
      button={
        <StyledUserInfoButton>
          <Avatar text={currentUser.name.initials} screenReaderText={currentUser.name.short} />
          <div>{currentUser.name.short}</div>
          <div>↓</div>
        </StyledUserInfoButton>
      }
    >
      <Text>{currentUser.name.full}</Text>
      <Button size="small" type="secondary" onClick={currentUser.signOut}>
        {translate('signOut')}
      </Button>
    </Dropdown>
  );
}
