import skapaToken from '@ingka/variables';
import Text from '@ingka/text';
import { Datetime } from 'components/common/Datetime';
import { useTranslate } from 'hooks/useTranslate';
import { NCACommentDto } from 'api/genTypes/dto';
import styled from 'styled-components';

export interface NCADetailsCommentsProps {
  comments?: NCACommentDto[];
}

const CommentStack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

const Comment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem 0.5rem;
  background-color: ${skapaToken.colourNeutralWhite};
  &:not(:last-child) {
    border-bottom: 1px solid ${skapaToken.colourBorderLight};
  }
`;

const CommentHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
`;

const CommentHeaderText = styled(Text)`
  color: ${skapaToken.colourTextLightest};
`;

const CommentBody = styled.div`
  color: ${skapaToken.colourTextDark};
  white-space: pre-line;
`;

export function NCADetailsComments(props: NCADetailsCommentsProps) {
  const t = useTranslate();

  if (!props.comments || props.comments.length === 0) {
    return <Text>{t('comments.empty')}</Text>;
  }

  return (
    <CommentStack>
      {props.comments.map((comment, i) => (
        <Comment key={`comment-${i}`} data-testid="NCADetailsCommentsComment">
          <CommentHeader>
            <CommentHeaderText bodySize="s">{comment.updatedBy}</CommentHeaderText>
            <CommentHeaderText bodySize="s">
              <Datetime date={comment.date} simple />
            </CommentHeaderText>
          </CommentHeader>
          <CommentBody>
            <Text>{comment.comment}</Text>
          </CommentBody>
        </Comment>
      ))}
    </CommentStack>
  );
}
