import {
  AddAssignmentRuleDto,
  AssignmentRuleDto,
  AssignmentRuleMatrixDtoSchema,
  UpdateAssignmentRuleMatrixDtoSchema,
} from 'api/genTypes/dto';
import { ldmAPI } from 'api/ldmApi';
import { useAppNotifications } from 'app/app.notifications';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import {
  ChangedRules,
  SelectedAssignment,
  UseLdmViewAssignmentsResult,
} from './ldmViewAssignments.types';

export function constructUpdateAssignmentRuleMatrixDtoSchema(
  changedRules: ChangedRules,
  rules: AssignmentRuleDto[]
): UpdateAssignmentRuleMatrixDtoSchema {
  const added: AddAssignmentRuleDto[] = rules.filter((rule) =>
    changedRules.added.includes(rule.id)
  );
  return {
    addedRules: added,
    removedRules: changedRules.removed.map((ruleId) => ({
      id: ruleId,
    })),
    updatedRules: rules.filter((rule) => changedRules.edited.includes(rule.id)),
  };
}

export function onHandleSubmit(
  changedRules: ChangedRules,
  rules: AssignmentRuleDto[],
  notifications: {
    showSuccessNotification: (msg: string) => void;
    showErrorNotification: (msg: string) => void;
  },
  setIsSubmitting: (loading: boolean) => void,
  setErrorMessage: (message: string) => void,
  setSuccessMessage: (message: string) => void,
  resetState: () => void,
  t: ReturnType<typeof useTranslate>
): Promise<void> {
  setIsSubmitting(true);

  return ldmAPI
    .setAssignments(constructUpdateAssignmentRuleMatrixDtoSchema(changedRules, rules))
    .then((response) => {
      notifications.showSuccessNotification('Assignments updated');
      setErrorMessage(null);
      setSuccessMessage(t('assignmentsUpdateSuccess'));
      return resetState();
    })
    .catch((response) => {
      notifications.showErrorNotification('Assignments update failed');
      setErrorMessage(response.response.data.errorMessage);
      setSuccessMessage(null);
      setIsSubmitting(false);
    });
}

export function onDeleteRule(
  id: number,
  isSubmitting: boolean,
  rules: AssignmentRuleDto[],
  setRules: (rules: AssignmentRuleDto[]) => void,
  changedRules: ChangedRules,
  setChangedRules: (changes: ChangedRules) => void
): void {
  if (isSubmitting) {
    return;
  }
  setRules(rules.filter((rule) => rule.id !== id));

  let previous = { ...changedRules };
  // if rule was added, remove from added
  if (changedRules.added.find((ruleId) => id === ruleId)) {
    previous.added = changedRules.added.filter((ruleId) => ruleId !== id);
  } else if (changedRules.edited.find((ruleId) => id === ruleId)) {
    // if rule was edited, remove from edited
    previous.edited = changedRules.edited.filter((ruleId) => ruleId !== id);
  }
  if (!previous.removed.includes(id)) {
    previous.removed.push(id);
  }
  setChangedRules(previous);
}

export function onEditRule(
  id: number,
  isSubmitting: boolean,
  rules: AssignmentRuleDto[],
  setSelectedRule: (rule: SelectedAssignment) => void
): void {
  if (isSubmitting) {
    return;
  }
  setSelectedRule({ rule: rules.find((rule) => rule.id === id) });
}

export function saveAddOrEditToTable(
  rules: AssignmentRuleDto[],
  setRules: (rules: AssignmentRuleDto[]) => void,
  selectedRule: SelectedAssignment,
  setSelectedRule: (selectedRule: SelectedAssignment) => void,
  changedRules: ChangedRules,
  setChangedRules: (changes: ChangedRules) => void
): void {
  const isNew: boolean = rules.find((rule) => rule.id === selectedRule.rule.id) === undefined;

  //If rule is not already in added or edited changed list, add to add/edited list
  if (
    !changedRules.added.find((id) => id === selectedRule.rule.id) &&
    !changedRules.edited.find((id) => id === selectedRule.rule.id)
  ) {
    if (isNew) {
      setChangedRules({
        ...changedRules,
        added: [...changedRules.added, selectedRule.rule.id],
      });
    } else {
      setChangedRules({
        ...changedRules,
        edited: [...changedRules.edited, selectedRule.rule.id],
      });
    }
  }
  setRules([...rules.filter((rule) => rule.id !== selectedRule.rule.id), selectedRule.rule]);
  setSelectedRule(null);
}

export function hasChanges(changes: ChangedRules): boolean {
  if (changes.added.length !== 0 || changes.edited.length !== 0 || changes.removed.length !== 0) {
    return true;
  }
  return false;
}

export function resetState(
  setChangedRules: (changes: ChangedRules) => void,
  setSuccessMessage: (message: string) => void,
  setErrorMessage: (message: string) => void,
  setSelectedRule: (selectedRule: SelectedAssignment) => void,
  query: UseQueryResult<AssignmentRuleMatrixDtoSchema, unknown>
): void {
  setChangedRules({
    added: [],
    edited: [],
    removed: [],
  });
  setSuccessMessage(null);
  setErrorMessage(null);
  setSelectedRule(null);

  query.refetch();
}

export function useLdmViewAssignments(): UseLdmViewAssignmentsResult {
  // Hooks
  const notifications = useAppNotifications();
  const t = useTranslate();

  //State
  const query = useQuery({
    queryKey: ['ldm-assignment-rules'],
    queryFn: () => ldmAPI.getAssignments(),
    staleTime: Infinity,
  });

  const [rules, setRules] = useState<AssignmentRuleDto[]>(null);

  useEffect(() => {
    if (!query.isFetching) {
      setRules(query.data.rules);
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.isFetching]);

  //State
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [successMessage, setSuccessMessage] = useState<string>(null);
  const [selectedRule, setSelectedRule] = useState<SelectedAssignment | null>(null);

  const [changedRules, setChangedRules] = useState<ChangedRules>({
    added: [],
    edited: [],
    removed: [],
  });

  return {
    query: query,
    submit: async () =>
      onHandleSubmit(
        changedRules,
        rules,
        notifications,
        setIsSubmitting,
        setErrorMessage,
        setSuccessMessage,
        () =>
          resetState(setChangedRules, setSuccessMessage, setErrorMessage, setSelectedRule, query),
        t
      ),
    rules,
    setRules,
    isSubmitting,
    errorMessage,
    successMessage,
    selectedRule,
    setSelectedRule,
    changedRules,
    setChangedRules,
    hasChanges: () => hasChanges(changedRules),
    onDeleteRule,
    onEditRule,
    saveAddOrEditToTable,
  };
}
