import { useNCAListFilter } from './hooks/useNCAListFilter';
import Button from '@ingka/button';
import { useTranslate } from 'hooks/useTranslate';
import { styled } from 'styled-components';
import { Row } from 'components/layout';

export const BadgeContent = styled.span`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
`;

export const BadgeValue = styled.span`
  font-style: italic;
`;

export const BadgeIcon = styled.span`
  border: 1px solid grey;
  padding: 0 0.3rem;
  border-radius: 1rem;
`;

const StyledWrappingRow = styled(Row)`
  gap: 0.5rem;
`;

export function NCAListFilterParamBadge({
  param,
  handleFilterCleared,
}: {
  param: any;
  handleFilterCleared: (key: string) => void;
}) {
  const t = useTranslate();

  return (
    <span onClick={() => handleFilterCleared(param.key)}>
      <Button
        text={
          <BadgeContent>
            <span>
              {t(param.key)}: <BadgeValue>{param.value}</BadgeValue>
            </span>
            <BadgeIcon>X</BadgeIcon>
          </BadgeContent>
        }
        size="xsmall"
        type="secondary"
      />
    </span>
  );
}

export function NCAListFilter() {
  const t = useTranslate();
  const { searchParams, handleFilterCleared, handleClearAllFilters } = useNCAListFilter();

  return (
    <StyledWrappingRow>
      <Button
        onClick={handleClearAllFilters}
        text={t('clearAllFilters')}
        size="small"
        type="primary"
        disabled={searchParams.length === 0}
      />
      {searchParams
        .filter((param) => param.key !== 'page')
        .map((param) => (
          <NCAListFilterParamBadge
            key={param.key}
            param={param}
            handleFilterCleared={handleFilterCleared}
          />
        ))}
    </StyledWrappingRow>
  );
}
