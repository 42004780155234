/** The stale time for API queries in milliseconds. */
export const API_QUERY_STALE_TIME: number = 20 * 1000;
/** The stale time for infrequent API queries in milliseconds */
export const API_INFREQUENT_QUERY_STALE_TIME: number = 60 * 60 * 1000;
/** The timeout for toast notifications in milliseconds */
export const TOAST_TIMEOUT: number = 10000;
/** The maximum number of attachments per claim */
export const MAX_NUMBER_OF_ATTACHMENTS = 10;
/** The maximum file size in bytes */
export const MAX_FILE_SIZE = 25 * 1024 * 1024; // 25 MB
/** The allowed file times for uploaded attachments */
export const ALLOWED_FILE_EXTENSIONS = [
  // Documents
  'doc',
  'docx',
  'odt',
  'txt',
  'pdf',

  // Spreadsheets
  'xls',
  'xlsx',
  'xlsm',
  'ods',
  'csv',

  // Presentations
  'ppt',
  'pptx',
  'odp',

  // Images
  'jpg',
  'jpeg',
  'png',
  'gif',
  'bmp',

  // Archives
  'zip',
  'rar',

  // Email
  'eml',
  'msg',
];
/** The min number of characters in the resolution comment */
export const RESOLUTION_COMMENT_MIN: number = 3;
/** The max number of characters in the resolution comment */
export const RESOLUTION_COMMENT_MAX: number = 500;
/** The max number of characters in work comment */
export const WORK_COMMMENT_MAX: number = 5000;
