import './NCADetails.css';
import { Subsection } from 'components/common/Subsection';
import { DataContentWrapper } from 'components/common/DataWrapper';
import { useNcaDetails } from './hooks/useNcaDetails';
import { useTranslate } from 'hooks/useTranslate';
import { NCADetailsBasicData } from './components/NCADetailsBasicData/NCADetailsBasicData';
import { NCAControls } from '../NCAControls/NCAControls';
import { NCADetailsShipmentStops } from './components/NCADetailsShipmentStops/NCADetailsShipmentStops';
import { NCADetailsEventHistory } from './components/NCADetailsEventHistory/NCADetailsEventHistory';
import Text from '@ingka/text';
import { NCADetailsAttachments } from './components/NCADetailsAttachments/NCADetailsAttachments';
import { NCADetailsAttachmentsUpload } from './components/NCADetailsAttachmentsUpload/NCADetailsAttachmentsUpload';
import { Stack } from 'components/layout';
import { NCADetailsComments } from './components/NCADetailsComments/NCADetailsComments';
import { GetOneNCAResponse } from 'api/genTypes/dto';
import { NCAWorkComment } from './components/NCAWorkComments/NCAWorkComment';

export function NCADetailsSections({ nca }: { nca?: GetOneNCAResponse }) {
  const translate = useTranslate();

  return (
    <>
      <Subsection header={<Text headingSize="s">{translate('claimDetails')}</Text>}>
        <NCADetailsBasicData nca={nca} />
      </Subsection>

      <Subsection header={<Text headingSize="s">{translate('carrierComments')}</Text>}>
        <NCADetailsComments comments={nca?.comments} />
      </Subsection>

      <Subsection header={<Text headingSize="s">{translate('shipmentStops')}</Text>}>
        <NCADetailsShipmentStops shipmentStops={nca?.shipmentStops} />
      </Subsection>

      <Subsection header={<Text headingSize="s">{translate('eventHistory')}</Text>}>
        <NCADetailsEventHistory eventHistory={nca?.eventHistory} />
      </Subsection>

      <Subsection header={<Text headingSize="s">{translate('attachments')}</Text>}>
        <Stack>
          <NCADetailsAttachments nca={nca} />
          <NCADetailsAttachmentsUpload ncas={[nca]} many={false} />
        </Stack>
      </Subsection>

      <Subsection header={<Text headingSize="s">{translate('workComments')}</Text>}>
        <NCAWorkComment
          comment={nca?.workNotes}
          ncaId={nca?.nonConformityAdviceId}
          enabled={nca?.isWorkNotesUpdateAllowed}
        />
      </Subsection>

      <Subsection header={<Text headingSize="s">{translate('handleNca')}</Text>}>
        <NCAControls nca={nca} />
      </Subsection>
    </>
  );
}

export function NCADetails() {
  const translate = useTranslate();
  const { data, isLoading, error } = useNcaDetails();

  return (
    <DataContentWrapper
      isLoading={isLoading}
      error={error}
      data={data}
      noDataMessage={translate('ncaNotFound')}
    >
      <NCADetailsSections nca={data} />
    </DataContentWrapper>
  );
}
