import { Select, Option } from '@ingka/select';

export function InputSelect({
  id,
  dataTestId,
  label,
  multiple,
  value,
  onChange,
  disabled,
  options,
}: {
  id: string;
  dataTestId: string;
  label: string;
  multiple?: boolean;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  options?: { label: string; value: string }[];
}) {
  return (
    <Select
      id={id}
      data-testid={dataTestId}
      label={label}
      multiple={multiple}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    >
      {options.map((item) => (
        <Option key={item.value} id={item.value} name={item.label} value={item.value} />
      ))}
    </Select>
  );
}
