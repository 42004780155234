import Button from '@ingka/button';
import Modal, { ModalFooter, Prompt } from '@ingka/modal';
import { useTranslate } from 'hooks/useTranslate';
import styled from 'styled-components';
import { useDisclosure } from 'utils/hooks';

const ConfirmationTitle = styled.div`
  margin-top: 1rem;
`;

export function onClickConfirm(disclosure: { open: () => unknown }, disabled?: boolean) {
  if (disabled) {
    return false;
  }
  disclosure.open();

  return true;
}

export function Confirmation({
  triggerElement,
  handleOnConfirmed,
  message,
  disabled,
}: {
  triggerElement: React.ReactNode;
  handleOnConfirmed: () => void;
  message?: string;
  disabled?: boolean;
}) {
  const t = useTranslate();
  const disclosure = useDisclosure();

  return (
    <>
      <Modal
        handleCloseBtn={() => disclosure.close()}
        // onModalOpened={() => handleOpen()}
        visible={disclosure.isOpen}
        style={{ zIndex: '100000' }}
      >
        <Prompt
          title={<ConfirmationTitle>{t('confirm')}</ConfirmationTitle>}
          titleId="confirm-modal"
          header={<></>}
          footer={
            <ModalFooter>
              <Button text={t('cancel')} type="secondary" onClick={() => disclosure.close()} />
              <Button
                text={t('confirm')}
                data-testid="confirmation.confirm"
                type="primary"
                onClick={() => {
                  disclosure.close();
                  handleOnConfirmed();
                }}
              />
            </ModalFooter>
          }
        >
          {message || t('areYouSure')}
        </Prompt>
      </Modal>

      <div onClick={() => onClickConfirm(disclosure, disabled)}>{triggerElement}</div>
    </>
  );
}
