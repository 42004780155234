import InputField from '@ingka/input-field';
import { ErrorAlert } from 'components/alerts';
import { Stack } from 'components/layout';
import { useTranslate } from 'hooks/useTranslate';
import { BackendFormControlResult } from './BackendForm.types';

export function backendFormControlFieldHandleChange(
  value: string | undefined,
  control: BackendFormControlResult
) {
  if (control.type === 'number') {
    const newValue = isNaN(Number(value)) ? undefined : Number(value);

    control.onChange(control.id, newValue);
  } else {
    control.onChange(control.id, value);
  }
}

export function BackendFormControlField({ control }: { control: BackendFormControlResult }) {
  const t = useTranslate();

  if (control.type === 'number' || control.type === 'text' || control.type === 'email') {
    return (
      <Stack>
        <InputField
          type={control.type}
          id={control.id}
          label={control.label}
          // Validation
          min={control.validation.min}
          minLength={control.validation.min}
          max={control.validation.max}
          maxLength={control.validation.max}
          // Values
          value={control.value}
          onChange={(e) => backendFormControlFieldHandleChange(e.target.value, control)}
        />
        {control.errors.map((error, i) => (
          <ErrorAlert key={i} title="" message={t(error)} />
        ))}
      </Stack>
    );
  }

  return null;
}
