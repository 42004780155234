import { NCADto } from 'api/genTypes/dto';
import { NcaStatuses } from 'pages/nca/constants/ncaStatuses';

export function isNCASelectable(alreadySelectedNCAs: Map<string, NCADto>, nca: NCADto): boolean {
  // The NCA must be in a state where it can be selected
  if (
    nca.status !== NcaStatuses.Open &&
    nca.status !== NcaStatuses.Reopened &&
    nca.status !== NcaStatuses.AdditionalInformationRequested
  ) {
    return false;
  }

  // If no NCAs have been selected, the NCA can always be selected
  if (alreadySelectedNCAs.size === 0) {
    return true;
  }

  const alreadySelectedNCAsArray = Array.from(alreadySelectedNCAs.values());

  // If the NCA cannot be selected
  if (
    !alreadySelectedNCAsArray.some(
      (alreadySelectedNCA) =>
        alreadySelectedNCA.tspId === nca.tspId &&
        alreadySelectedNCA.flow === nca.flow &&
        alreadySelectedNCA.costType === nca.costType
    )
  ) {
    return false;
  }

  // If all conditions are met, the NCA can be selected
  return true;
}
