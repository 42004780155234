import { NCADto } from 'api/genTypes/dto';
import create from 'zustand';

export interface UseNCAControlsMultiSelectStoreUpdate {
  items: Map<string, NCADto>;
}

export interface UseNCAControlsMultiSelectStoreState {
  selectedItems: Map<string, NCADto>;
  nbrOfItems: number;
  totalAmount: number;
}

export interface UseNCAControlsMultiSelectStore extends UseNCAControlsMultiSelectStoreState {
  setState: (state: UseNCAControlsMultiSelectStoreUpdate) => void;
  deselectSome: (ids: string[]) => void;
}

export function setState(
  update: UseNCAControlsMultiSelectStoreUpdate
): UseNCAControlsMultiSelectStoreState {
  const updatedArray: NCADto[] = Array.from(update.items.values());
  return {
    selectedItems: update.items,
    nbrOfItems: update.items.size,
    totalAmount: updatedArray.reduce(
      (previous, current) => previous + (current.amountEuro || 0),
      0
    ),
  };
}

export function deselectSome(
  state: UseNCAControlsMultiSelectStoreState,
  ids: string[]
): UseNCAControlsMultiSelectStoreState {
  ids.forEach((id) => {
    state.selectedItems.delete(id);
  });
  return {
    selectedItems: state.selectedItems,
    nbrOfItems: state.selectedItems.size,
    totalAmount: Array.from(state.selectedItems.values()).reduce(
      (previous, current) => previous + (current.amountEuro || 0),
      0
    ),
  };
}

export const useNCAControlsMultiSelectStore = create<UseNCAControlsMultiSelectStore>((set) => ({
  selectedItems: new Map<string, NCADto>(),
  nbrOfItems: 0,
  totalAmount: 0,
  setState: (update: UseNCAControlsMultiSelectStoreUpdate) => set(() => setState(update)),
  deselectSome: (ids: string[]) =>
    set((state) => deselectSome(state as UseNCAControlsMultiSelectStoreState, ids)),
}));
