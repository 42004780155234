import { useCallback, useEffect, useState } from 'react';
import { BackendFormControlResult, BackendFormValueType } from '../BackendForm.types';
import { BackendFormProps } from '../BackendForm.types';
import { BackendFormResult } from '../BackendForm.types';
import { BackendFormValues } from '../BackendForm.types';
import { initBackendFormResult } from '../backendForm.utils';
import { BackendFormControl, BackendFormControlValidation } from 'api/genTypes/dto';

export function calculateChangedFormControlLengthOfValue(
  formControlNewValue: BackendFormValueType
): number {
  let lengthOfValue: number = 0;

  if (typeof formControlNewValue === 'string') {
    lengthOfValue = formControlNewValue.length;
  } else if (typeof formControlNewValue === 'number') {
    lengthOfValue = formControlNewValue;
  }

  return lengthOfValue;
}

export function calculateChangedFormControlErrors(
  formControlNewValue: BackendFormValueType,
  lengthOfValue: number,
  validation: BackendFormControlValidation | null | undefined
): string[] {
  if (!validation) {
    return [];
  }

  const errors: string[] = [
    validation.required && formControlNewValue === undefined
      ? 'validation.error.required'
      : undefined,
    lengthOfValue < validation.min ? 'validation.error.range.min' : undefined,
    lengthOfValue > validation.max ? 'validation.error.range.max' : undefined,
  ].filter((error) => error);

  return errors;
}

export function generateNewFormResult(
  updatedFormControl: BackendFormControlResult,
  previousFormResult: BackendFormResult
): BackendFormResult {
  const updatedControls = previousFormResult.controls.map((control) =>
    control.id === updatedFormControl.id ? updatedFormControl : control
  );

  // Errors
  const allErrorsFromTheNewControls = updatedControls.flatMap((control) => control.errors);

  // Values
  const values: BackendFormValues = [];
  const changedValues: BackendFormValues = [];

  updatedControls.forEach((control) => {
    values.push({ id: control.id, value: control.value });

    if (control.isChanged) {
      changedValues.push({ id: control.id, value: control.value });
    }
  });

  // Return the new state
  return {
    ...previousFormResult,
    controls: updatedControls,
    errors: allErrorsFromTheNewControls,
    isChanged: updatedControls.some((control) => control.isChanged),
    valid: allErrorsFromTheNewControls.length === 0,
    values: values,
    changedValues: changedValues,
  };
}

export function generateNewFormControlResult(
  formControlNewValue: BackendFormValueType,
  versionOfControlProvidedByBackend: BackendFormControl,
  handleChangeFormControl: (id: string, value: BackendFormValueType) => void
): BackendFormControlResult {
  // Check if the value has changed
  const isChanged: boolean = versionOfControlProvidedByBackend.defaultValue !== formControlNewValue;

  // Validation
  const lengthOfValue: number = calculateChangedFormControlLengthOfValue(formControlNewValue);
  const errors: string[] = calculateChangedFormControlErrors(
    formControlNewValue,
    lengthOfValue,
    versionOfControlProvidedByBackend.validation
  );

  // Create the updated form control
  const updatedFormControl: BackendFormControlResult = {
    ...versionOfControlProvidedByBackend,
    value: formControlNewValue,
    errors: errors,
    isChanged: isChanged,
    isValid: errors.length === 0,
    onChange: handleChangeFormControl,
  };

  return updatedFormControl;
}

export function calculateChangedFormControl(
  formControlId: string,
  formControlNewValue: BackendFormValueType,
  previousFormResult: BackendFormResult,
  handleChangeFormControl: (id: string, value: BackendFormValueType) => void,
  formPropsFromBackend?: BackendFormProps
): BackendFormResult {
  // Try to find the control in the form. We use the form from props to ensure
  // that we are not using stale state and use the most recent form provided.
  const versionOfControlProvidedByBackend = formPropsFromBackend?.controls.find(
    (c) => c.id === formControlId
  );

  // If the control does not exist, we do nothing
  if (!versionOfControlProvidedByBackend) {
    return previousFormResult;
  }

  // Update the control
  const updatedFormControl: BackendFormControlResult = generateNewFormControlResult(
    formControlNewValue,
    versionOfControlProvidedByBackend,
    handleChangeFormControl
  );

  // Update the entire form based on the updated control
  const updatedFormResult: BackendFormResult = generateNewFormResult(
    updatedFormControl,
    previousFormResult
  );

  return updatedFormResult;
}

export function useBackendForm(form?: BackendFormProps): BackendFormResult {
  // Functions
  const handleChangeFormControl = useCallback(
    (id: string, newValue: BackendFormValueType) => {
      setFormResult((prev) =>
        calculateChangedFormControl(id, newValue, prev, handleChangeFormControl, form)
      );
    },
    [form]
  );

  // State
  const [formResult, setFormResult] = useState<BackendFormResult>(
    initBackendFormResult(form, handleChangeFormControl)
  );

  // Side effects
  useEffect(() => {
    setFormResult(initBackendFormResult(form, handleChangeFormControl));
  }, [form, handleChangeFormControl]);

  // Result
  return formResult;
}
