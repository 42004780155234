import { ErrorAlert, InfoAlert } from 'components/alerts';
import { useTranslate } from 'hooks/useTranslate';
import { NCAControlsStateForSubmitting } from '../../NCAControls.types';

export function NCAControlsSubmissionNotifications({
  submittingState,
}: {
  submittingState: NCAControlsStateForSubmitting;
}) {
  const t = useTranslate();
  if (submittingState.ncaResponse && submittingState.ncaResponse.failed.length > 0) {
    let updatedClaimIds: string[] = [
      ...submittingState.ncaResponse.approved,
      ...submittingState.ncaResponse.rejected,
      ...submittingState.ncaResponse.sent_to_ldm,
    ];
    if (updatedClaimIds.length > 0) {
      return (
        <>
          <ErrorAlert
            message={t('claimUpdateFailed') + ' ' + submittingState.ncaResponse.failed.join()}
            title={t('error')}
            data-testid={'errorAlert-testId'}
          />
          <InfoAlert
            message={t('claimUpdated') + ' ' + updatedClaimIds.join()}
            title={t('someClaimsUpdate')}
            data-testid={'infoAlert-testId'}
          />
        </>
      );
    } else {
      return (
        <ErrorAlert
          message={t('claimUpdateFailed') + ' ' + submittingState.ncaResponse.failed.join()}
          title={t('error')}
          data-testid={'errorAlert-testId'}
        />
      );
    }
  }
  return <></>;
}
