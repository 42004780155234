import { attachmentsAPI } from 'api/attachmentsApi';
import {
  ALLOWED_FILE_EXTENSIONS,
  MAX_FILE_SIZE,
  MAX_NUMBER_OF_ATTACHMENTS,
} from 'app/app.constants';
import { getRandomString } from 'utils/randomString';
import {
  leastNbrOfAttachmentSpaceLeft,
  getErrorMessagesForAttachments,
} from './NCADetailsAttachmentsUpload.utils';
import { useState } from 'react';
import { useAppNotifications } from 'app/app.notifications';
import { useTranslate } from 'hooks/useTranslate';
import { NcaForAttachmentsUpload } from './NCADetailsAttachmentsUpload.types';
import { useNcaDetails } from '../../hooks/useNcaDetails';
import { useNCAList } from 'pages/nca/components/NCAList/hooks/useNCAList';

export function clearFiles(
  setFiles: (input: File[]) => void,
  setFileInputKey: (input: string) => void
) {
  setFiles([]);
  setFileInputKey(getRandomString());
}

export function submit(
  ncas: NcaForAttachmentsUpload[],
  many: boolean,
  isSubmitDisabled: boolean,
  setIsLoading: (input: boolean) => void,
  files: File[],
  notifications: {
    showSuccessNotification: (msg: string) => void;
    showErrorNotification: (msg: string) => void;
  },
  clearFiles: () => void,
  refetch: () => void,
  refetchList: () => void,
  t: ReturnType<typeof useTranslate>
) {
  if (isSubmitDisabled) {
    return;
  }
  setIsLoading(true);

  attachmentsAPI
    .upload(
      ncas.map((nca) => nca.nonConformityAdviceId),
      files
    )
    .then(() => {
      notifications.showSuccessNotification(t('attachments.upload.submit.success'));
      clearFiles();
      if (many) {
        refetchList();
      } else {
        refetch();
      }
    })
    .catch(() => {
      notifications.showErrorNotification(t('attachments.upload.submit.error'));
    })
    .finally(() => {
      setIsLoading(false);
    });
}

export function useNCADetailsAttachmentsUpload(ncas: NcaForAttachmentsUpload[], many?: boolean) {
  /*
   ** Hooks
   */
  const t = useTranslate();
  const notifications = useAppNotifications();
  const { refetch } = useNcaDetails();
  const { refetch: refetchList } = useNCAList();

  /*
   ** State
   */
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // We need to change the key of the input to reset the input value. Why?
  // Because file inputs cannot be controlled directly by React.
  const [fileInputKey, setFileInputKey] = useState<string>(getRandomString());

  /*
   ** Calculated Values
   */
  const accept: string = ALLOWED_FILE_EXTENSIONS.map((ext) => `.${ext}`).join(',');
  const nbrAttachmentsCanBeAdded: number = leastNbrOfAttachmentSpaceLeft(
    MAX_NUMBER_OF_ATTACHMENTS,
    ncas
  );
  const errors: string[] = getErrorMessagesForAttachments(
    files,
    MAX_FILE_SIZE,
    ALLOWED_FILE_EXTENSIONS,
    nbrAttachmentsCanBeAdded
  );
  const isSubmitDisabled: boolean = !files.length || errors.length > 0 || isLoading;

  /*
   ** Result
   */
  const formControl = {
    key: fileInputKey,
    name: 'attachments',
    description: t('attachments.upload.description'),
    error: errors,
    type: 'file',
    accept,
  };

  return {
    setFiles,
    clearFiles: () => clearFiles(setFiles, setFileInputKey),
    formControl,
    isSubmitDisabled,
    isLoading,
    submit: () =>
      submit(
        ncas,
        many,
        isSubmitDisabled,
        setIsLoading,
        files,
        notifications,
        () => clearFiles(setFiles, setFileInputKey),
        refetch,
        refetchList,
        t
      ),
  };
}
