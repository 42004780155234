import { useAccount, useMsal } from '@azure/msal-react';
import { msalInstance } from 'app/app.providers';
import { useSignOut } from './components/AppHeader/hooks/useSignOut';
import { filterParenthesisInfo } from './components/AppHeader/utils/filterParenthesisInfo';
import { getUserInitial } from './components/AppHeader/utils/getUserInitial';
import { RedirectRequest } from '@azure/msal-browser';

/** Acquire an access token for the given scopes
 * @param apiScopes The scopes to request
 * @returns The access token
 * @throws Falls back to interaction if silent acquisition fails
 */
export async function acquireAccessToken(apiScopes: string[]) {
  // 1. Get the active account
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  // 2. If there is no active account, redirect to the login page
  if (!activeAccount && accounts.length === 0) {
    window.location.href = '/';
    return;
  }

  // 3. Create a request object for acquireTokenSilent
  const requestSilent: RedirectRequest = {
    scopes: apiScopes,
    account: activeAccount || accounts[0],
  };

  // 4. Try to acquire token silently and return it
  return (
    msalInstance
      // 4a. Try to acquire token silently
      .acquireTokenSilent(requestSilent)
      // 4b. If silent acquisition succeeds, return the token
      .then((authResult) => authResult.accessToken)
      // 4c. If silent acquisition fails, fallback to interaction
      .catch(async (error: any) => {
        console.error('[acquireAccessToken]', 'redirecting due to', error);

        // Ensure we redirect to the base URL, in order to avoid
        // a redirect error since only the base URL is allowed
        // as a redirect URI in the Azure AD app registration
        const redirectUri = window.location.origin;
        const requestRedirect: RedirectRequest = {
          scopes: requestSilent.scopes,
          account: requestSilent.account,
          redirectUri,
        };

        return msalInstance.acquireTokenRedirect(requestRedirect);
      })
      // 4d. If interaction fails, redirect to login
      // (This probably won't work due to the previous catch block redirecting to a new page)
      .catch((error) => {
        console.error('[acquireAccessToken]', 'redirecting to login due to', error);
        msalInstance.loginRedirect();
      })
  );
}

/** Returns the current user's information. */
export function useCurrentUser() {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const signOut = useSignOut(account);

  // Email
  const email = account.username;

  // Name
  const name: string = account.name;
  const nameShort: string = filterParenthesisInfo(account.name);
  const userInitials: string = getUserInitial(account.name);

  // Role
  const claims = account.idTokenClaims;
  const roles = claims?.roles || [];

  let role: string | undefined;

  if (roles.includes('LDM')) {
    role = 'LDM';
  } else if (roles.includes('SUPER_USER')) {
    role = 'SUPER_USER';
  } else if (roles.includes('LDS')) {
    role = 'LDS';
  }

  // TODO: Remove this once real user roles are implemented.
  role = 'LDS';

  const isLdm = role === 'LDM';
  const isLds = role === 'LDS';
  const isSuperUser = role === 'SUPER_USER';
  const isECHUser = isLdm || isLds || isSuperUser;
  const isElevatedUser = isLdm || isSuperUser;

  // Return the user object
  return {
    /** Technical details about the user's account. */
    account,
    /** The user's name and variations of it for various display purposes. */
    name: {
      /** Full name, including parenthesis info found on external accounts. */
      full: name,
      /** Same as full name, but without the parenthesis info found on external accounts. */
      short: nameShort,
      /** Initials of the user's name. Uses the first letter of the first two names,
       * or the first two letters of a single name. */
      initials: userInitials,
    },
    /** The email of the user. Retrieved from the username. */
    email,
    /** Details about the current user's role. */
    role: {
      /** The current role of the user. */
      current: role,
      /** Returns `true` if the user is an LDM. */
      isLdm,
      /** Returns `true` if the user is an LDS. */
      isLds,
      /** Returns `true` if the user is a SUPER_USER. */
      isSuperUser,
      /** Returns `true` if the user is an elevated user, i.e. has any elevated role at all. */
      isElevatedUser,
      /** Returns `true` if the user is an ECH user, i.e. has any user role at all. */
      isECHUser,
      /** Returns `true` if the user has the given role. */
      hasRole: (role: string) => roles.includes(role),
    },
    /** Signs out the user. This will redirect the user to the login page. */
    signOut,
    /** Returns `true` if the user's email matches the given email. */
    equals: (otherUsername: string) => email === otherUsername,
    /** Returns `true` if the user is signed in. */
    isSignedIn: !!email,
  };
}

export type CurrentUser = ReturnType<typeof useCurrentUser>;
