import { configurationsAPI } from 'api/configurationsApi';
import { ConfigurationsSchema } from 'api/genTypes/dto';
import { useQuery } from 'react-query';
import { ConfigurationRejectionReasonCode } from './useConfigurations.types';
import { API_INFREQUENT_QUERY_STALE_TIME } from 'app/app.constants';

export function useConfigurations(classification: string) {
  return useQuery<ConfigurationsSchema>({
    // The params here mean that every time we change our search params,
    // the query is invalidated and must be refetched.
    queryKey: ['CONFIGURATIONS', classification],
    queryFn: () => configurationsAPI.fetchMany(classification),
    // We don't expect classification data to change very often, so we only refresh once per hour.
    // Manually refreshing the whole page will also refresh this data.
    staleTime: API_INFREQUENT_QUERY_STALE_TIME,
  });
}

export function getRejectionReasonCodes(
  data?: ConfigurationsSchema,
  isLoading?: boolean,
  error?: any
): ConfigurationRejectionReasonCode[] {
  if (isLoading) {
    return [];
  }

  if (error) {
    return [];
  }

  if (!data || !data.items) {
    return [];
  }

  return data.items
    .map((item) => ({
      code: item?.key,
      message: item?.key + ' - ' + (item?.value as any)['message'],
    }))
    .filter((item) => item.code && item.message);
}

export function useRejectionReasonCodes(): ConfigurationRejectionReasonCode[] {
  const { data, isLoading, error } = useConfigurations('NCARejectionReason');

  return getRejectionReasonCodes(data, isLoading, error);
}
