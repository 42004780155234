import {
  DataTableEntity,
  DataTableSelectedItems,
  DataTableValidateSelection,
} from './DataTable.types';

export function areAllVisibleItemsThatCanBeSelectedSelected<T extends DataTableEntity>(
  visibleItems: T[],
  selectedItems: DataTableSelectedItems<T>,
  validateSelection?: DataTableValidateSelection<T>,
  idField?: Extract<keyof T, string>
): boolean {
  // visibleItems are a subset of all items (or may be all items)
  // selectedItems are all selected item. Selected items may be a subset of visible items
  // or may be part of items that are not visible.
  // We need to check if all visible items that can be selected are selected.
  if (visibleItems && visibleItems.length === 0) {
    return false;
  }

  return (
    selectedItems.size ===
    createNewSelectionWithAddedItems(selectedItems, visibleItems, idField, validateSelection).size
  );
}

export function createNewSelectionWithRemovedItems<T extends DataTableEntity>(
  currentlySelectedItems: DataTableSelectedItems<T>,
  items: T[],
  idField?: string
): DataTableSelectedItems<T> {
  // If we have no id field, we can't handle selection. Just return the current selection.
  if (!idField) {
    return currentlySelectedItems;
  }

  // Create a new copy of the currently selected items
  const updatedSelectedItems = new Map<string, T>(currentlySelectedItems);

  // Remove the items from the selection
  items.forEach((item) => {
    updatedSelectedItems.delete(item[idField]);
  });

  // Return the updated selection
  return updatedSelectedItems;
}

function findItemsThatAreValidToAddToSelection<T extends DataTableEntity>(
  selectedItems: DataTableSelectedItems<T>,
  items: T[],
  validateIfItemCanBeAddedToSelection?: DataTableValidateSelection<T>
): T[] {
  // If we don't have a validation function, we can add all items to the selection.
  if (!validateIfItemCanBeAddedToSelection) {
    return items;
  }

  // Otherwise, we filter only the items that are valid to add to the selection.
  return items.filter((item) => validateIfItemCanBeAddedToSelection(selectedItems, item));
}

/** Create a new selection based on the currently selected items and the items to add to the selection. */
export function createNewSelectionWithAddedItems<T extends DataTableEntity>(
  currentlySelectedItems: DataTableSelectedItems<T>,
  itemsToAddToSelection: T[],
  idField?: Extract<keyof T, string>,
  validateIfItemCanBeAddedToSelection?: DataTableValidateSelection<T>
): DataTableSelectedItems<T> {
  // If we have no id field, we can't handle selection. Just return the current selection.
  if (!idField) {
    return currentlySelectedItems;
  }

  // Create a new copy of the currently selected items
  const updatedSelectedItems = new Map<string, T>(currentlySelectedItems);

  // Find the items that are valid to add to the selection
  let itemsValidToAddToSelection = findItemsThatAreValidToAddToSelection(
    currentlySelectedItems,
    itemsToAddToSelection,
    validateIfItemCanBeAddedToSelection
  );

  // Add the items to the selection
  itemsValidToAddToSelection.forEach((item) => {
    updatedSelectedItems.set(item[idField], item);
  });

  // Return the updated selection
  return updatedSelectedItems;
}

export function createNewSelectionWithAddedOrRemovedItems<T extends DataTableEntity>(
  checked: boolean,
  selectedItems: DataTableSelectedItems<T>,
  items: T[],
  idField?: Extract<keyof T, string>,
  validateSelection?: DataTableValidateSelection<T>
): DataTableSelectedItems<T> {
  if (checked) {
    return createNewSelectionWithAddedItems(selectedItems, items, idField, validateSelection);
  }

  return createNewSelectionWithRemovedItems(selectedItems, items, idField);
}
