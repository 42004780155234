import { NCAWorkNotesDto } from 'api/genTypes/dto';
import { useTranslate } from 'hooks/useTranslate';
import Text from '@ingka/text';
import { useNCAWorkComment } from './useNCAWorkComment';
import FormField from '@ingka/form-field';
import TextArea from '@ingka/text-area';
import Button from '@ingka/button';
import { Confirmation } from 'components/common/Confirmation/Confirmation';
import { Stack } from 'components/layout';
import { Datetime } from 'components/common/Datetime';
import { WORK_COMMMENT_MAX } from 'app/app.constants';

export interface NCAWorkCommentProps {
  ncaId: string;
  comment?: NCAWorkNotesDto;
  enabled?: boolean;
}

export function NCAWorkComment(props: NCAWorkCommentProps) {
  const { workComment, setWorkComment, hasChanges, workCommentError, isSubmitting, submit } =
    useNCAWorkComment(props.ncaId, props.comment);
  const t = useTranslate();

  return (
    <>
      <FormField
        characterLimit={WORK_COMMMENT_MAX}
        valid={!workCommentError}
        validation={{
          id: 'work-comment-error-msg-id',
          msg: workCommentError,
          type: 'error',
        }}
        style={{
          flexGrow: 1,
          minWidth: '50%',
          height: '100%',
          margin: props.comment?.comment && !props.enabled ? '0 0 0 16' : '0 0 0 0',
        }}
        disabled={!props.enabled}
        shouldValidate={props.enabled && !!workCommentError}
      >
        <TextArea
          id="work-comment-input"
          label={t('workComment')}
          style={{ height: '100%' }}
          value={workComment}
          onChange={(e) => setWorkComment(e.target.value)}
          readOnly={!props.enabled || isSubmitting}
        />
      </FormField>
      <Stack>
        {props.comment?.comment && (
          <>
            <Text data-testid={'work-comment-updated-by'}>
              {t('lastUpdatedBy') + ': ' + props.comment.updatedBy}
            </Text>
            <Text data-testid={'work-comment-last-updated-date'}>
              {t('lastUpdated') + ': '} <Datetime date={props.comment.date} simple />
            </Text>
          </>
        )}
        {props.enabled && (
          <Confirmation
            handleOnConfirmed={submit}
            triggerElement={
              <Button
                type="primary"
                disabled={!props.enabled || !hasChanges || isSubmitting || workComment.length === 0}
                loading={isSubmitting}
                data-testid="update-work-comment-button"
                style={{ margin: '16 0 0 0' }}
              >
                {t('updateWorkComment')}
              </Button>
            }
            disabled={!props.enabled || !hasChanges || isSubmitting || workComment.length === 0}
          />
        )}
      </Stack>
    </>
  );
}
