import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { appConfig } from 'app/app.config';
import { createBrowserHistory } from 'history';

export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: appConfig.appInsightKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: createBrowserHistory() },
    },
  },
});

appInsights.loadAppInsights();
