import Button from '@ingka/button';
import skapaToken from '@ingka/variables';
import { Select, Option } from '@ingka/select';
import Accordion, { AccordionItem } from '@ingka/accordion';
import { useTranslate } from 'hooks/useTranslate';
import {
  NCAControlsStateForActions,
  NCAControlsStateForReasonCodes,
  NCAControlsStateForSubmitting,
  NCAControlsStateForCausingParties,
} from '../../NCAControls.types';
import { Confirmation } from 'components/common/Confirmation/Confirmation';
import styled from 'styled-components';
import { Row, Stack, StackSpaced } from 'components/layout';
import { InputSelect } from 'components/InputSelect';
import { NCADto, NCADtoMessages } from 'api/genTypes/dto';
import { InfoAlert } from 'components/alerts';
import { NcaActions } from 'pages/nca/constants/ncaActions';

const ButtonFullWidth = styled(Button)`
  width: 100%;
`;
const RowButtons = styled(Stack)`
  justify-content: flex-end;
`;

// TODO: This component should be broken up to make unit testing possible/easier
export function NCAControlsActionSelect({
  isAnyClaimActionPossible,
  actionState,
  reasonCodeState,
  causingPartyState,
  submittingState,
  onDeselect,
  messages,
  ncas,
}: {
  isAnyClaimActionPossible?: boolean;
  actionState: NCAControlsStateForActions;
  reasonCodeState: NCAControlsStateForReasonCodes;
  causingPartyState: NCAControlsStateForCausingParties;
  submittingState: NCAControlsStateForSubmitting;
  onDeselect?: () => void;
  messages?: NCADtoMessages;
  ncas: NCADto[];
}) {
  const t = useTranslate();
  const claimActionMessages: string[] = [
    ...(messages?.approvalMessages || []),
    ...(messages?.rejectionMessages || []),
    ...(messages?.sendToLdmMessages || []),
    ...(messages?.additionalInformationRequestMessages || []),
  ].filter((message) => message);
  const nbrOfNCAs = ncas.length;
  const amountEuro = ncas.reduce((acc, nca) => acc + (nca.amountEuro || 0), 0);
  const getConfirmationMessage = () => {
    if (actionState.action === NcaActions.Approve || actionState.action === NcaActions.Reject) {
      return `${t(actionState.action)} ${nbrOfNCAs} claim${
        nbrOfNCAs > 1 ? 's' : ''
      }, ${amountEuro} EUR?`;
    }

    // Otherwise we return undefined, so that the default confirmation message is used.
    return undefined;
  };

  return (
    <StackSpaced>
      {/* Select: Action */}
      <Select
        id="NCAControlsActionSelectId"
        data-testid="NCAControlsActionSelectId"
        label={t('selectAction')}
        multiple={false}
        value={actionState.action}
        onChange={(e) => actionState.setAction(e.target.value)}
        disabled={!isAnyClaimActionPossible || submittingState.isSubmitting}
      >
        {actionState.actionOptions.map((action) => (
          <Option
            key={'nca-controls-action-' + action.key}
            id={action.key}
            name={t(action.key)}
            value={action.key}
            disabled={action.disabled}
          />
        ))}
      </Select>

      {/* Select: Causing Party */}
      <InputSelect
        id="NCAControlsCausingPartySelectId"
        dataTestId="NCAControlsCausingPartySelectId"
        label={t('causingParty')}
        multiple={false}
        value={causingPartyState.causingParty}
        onChange={(e) => causingPartyState.setCausingParty(e)}
        disabled={causingPartyState.isCausingPartyDropdownDisabled || submittingState.isSubmitting}
        options={causingPartyState.causingPartyOptions.map((causingParty) => ({
          label: causingParty.name,
          value: causingParty.businessUnit,
        }))}
      ></InputSelect>
      {actionState.action === NcaActions.Approve && !causingPartyState.causingParty && (
        <div style={{ color: skapaToken.colourBrandRed }}>{t('ncaCausingPartyMissing')}</div>
      )}

      {/* Select: Reason Code */}
      <Select
        id="NCAControlsReasonCodeSelectId"
        data-testid="NCAControlsReasonCodeSelectId"
        label={t('reasonCode')}
        multiple={false}
        value={reasonCodeState.reasonCode}
        onChange={(e) => reasonCodeState.setReasonCode(e.target.value)}
        disabled={reasonCodeState.isReasonCodesDropdownDisabled || submittingState.isSubmitting}
      >
        {/* <Option id="reasonCode-noValue" name="-" value={''} /> */}
        {reasonCodeState.reasonCodeOptions.map((reasonCode) => (
          <Option
            key={'reasonCode-' + reasonCode.code}
            id={reasonCode.code}
            name={reasonCode.message}
            value={reasonCode.code}
          />
        ))}
      </Select>
      {actionState.action === NcaActions.Reject && !reasonCodeState.reasonCode && (
        <div style={{ color: skapaToken.colourBrandRed }}>{t('ncaRejectionReasonMissing')}</div>
      )}

      {/* Submit changes */}

      <RowButtons>
        <Row>
          {/* DESELECT (Multi-select only) */}
          {onDeselect && (
            <Button
              type="secondary"
              disabled={submittingState.isSubmitting}
              onClick={() => {
                onDeselect();
              }}
            >
              {t('ncaMultiSelectButtonDeselect')}
            </Button>
          )}

          {/* SUBMIT */}
          <Confirmation
            handleOnConfirmed={submittingState.handleOnSubmit}
            message={getConfirmationMessage()}
            triggerElement={
              <ButtonFullWidth
                type="emphasised"
                disabled={submittingState.isSubmitDisabled}
                loading={submittingState.isSubmitting}
              >
                {t('saveAndSubmit')}
              </ButtonFullWidth>
            }
            disabled={submittingState.isSubmitDisabled || submittingState.isSubmitting}
          />
        </Row>
      </RowButtons>
      {claimActionMessages.length > 0 && (
        <Accordion size="small">
          <AccordionItem
            id="nca-details-messages-accordion"
            title={t('claimActionHint')}
            onHeadingClicked={() => {}}
            subtle
          >
            {claimActionMessages.map((message, index) => (
              <InfoAlert key={index} title="" message={message} />
            ))}
          </AccordionItem>
        </Accordion>
      )}
    </StackSpaced>
  );
}
