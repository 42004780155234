import {
  BackendFormProps,
  OnChangeFormControl,
  BackendFormResult,
  BackendFormControlProps,
  BackendFormControlResult,
} from './BackendForm.types';

export function initBackendFormResult(
  form: BackendFormProps | undefined,
  onChange: OnChangeFormControl
): BackendFormResult {
  if (!form) {
    return {
      controls: [],
      values: [],
      changedValues: [],
      errors: [],
    };
  }

  return {
    controls: form.controls.map((control) => initBackendFormControlResult(control, onChange)),
    values: form.controls.map((control) => ({
      id: control.id,
      value: control.defaultValue,
    })),
    changedValues: [],
    errors: [],
  };
}
export function initBackendFormControlResult(
  control: BackendFormControlProps,
  onChange: OnChangeFormControl
): BackendFormControlResult {
  return {
    ...control,
    value: control.defaultValue,
    errors: [],
    isValid: true,
    isChanged: false,
    onChange: onChange,
  };
}
