import Loading, { LoadingBall } from '@ingka/loading';
import 'components/common/DataWrapper.css';
import { useTranslate } from 'hooks/useTranslate';
import { NoData, UnexpectedError } from 'components/alerts';
import styled from 'styled-components';

const LoadingMargin = styled.div`
  margin-top: 5rem;
  margin-bottom: 5rem;
`;

export function DataContentWrapper({
  children,
  isLoading,
  error,
  data,
  noDataMessage,
}: {
  children?: JSX.Element;
  isLoading: boolean;
  error: any;
  data: any;
  noDataMessage?: string;
}) {
  const translate = useTranslate();

  if (isLoading) {
    return (
      <LoadingMargin className="data-wrapper--is-loading" role="alert">
        <Loading text={translate('loading')}>
          <LoadingBall color="emphasised" size="large" />
        </Loading>
      </LoadingMargin>
    );
  }

  if (error) {
    return <UnexpectedError />;
  }

  const hasNoDataToDisplay = !data || data.length === 0;

  if (hasNoDataToDisplay) {
    return <NoData />;
  }

  return children;
}
