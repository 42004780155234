import { GetOneNCAResponse, NCADto } from 'api/genTypes/dto';
import { NcaForAttachmentsUpload } from './NCADetailsAttachmentsUpload.types';

export function getNbrOfAttachmentsOnNCA(nca: GetOneNCAResponse | NCADto | undefined): number {
  if (!nca) {
    return 0;
  }

  if ('attachments' in nca && nca.attachments) {
    return nca.attachments.length;
  } else if ('nbrOfAttachments' in nca) {
    return nca.nbrOfAttachments;
  }

  return 0;
}

function isFileSizeValid(file: File, maxFileSize: number): boolean {
  return file.size <= maxFileSize;
}

export function isFileExtensionValid(file: string, allowedFileExtensions: string[]): boolean {
  const fileExtension = file.split('.').pop().toLowerCase();

  if (!fileExtension) {
    return false;
  }

  return allowedFileExtensions.includes(fileExtension);
}

export function getErrorMessageForAttachment(
  file: File,
  maxFileSize: number,
  allowedFileExtensions: string[]
): string | undefined {
  if (!isFileSizeValid(file, maxFileSize)) {
    return 'attachments.upload.error.fileSize';
  }

  if (!isFileExtensionValid(file.name, allowedFileExtensions)) {
    return 'attachments.upload.error.fileExtension';
  }

  return undefined;
}

export function leastNbrOfAttachmentSpaceLeft(
  maxNbrOfAttachments: number,
  ncas: NcaForAttachmentsUpload[]
): number {
  return ncas.reduce(
    (leastAmountOfSpaceLeftOnAllNCAs, nca) =>
      Math.min(
        leastAmountOfSpaceLeftOnAllNCAs,
        maxNbrOfAttachments - getNbrOfAttachmentsOnNCA(nca)
      ),
    maxNbrOfAttachments
  );
}

export function getErrorMessagesForAttachments(
  files: File[],
  maxFileSize: number,
  allowedFileExtensions: string[],
  leastNbrOfAttachmentSpaceLeft: number
): string[] {
  const errors: string[] = files
    .map((file) => getErrorMessageForAttachment(file, maxFileSize, allowedFileExtensions))
    .filter((errorMessage): errorMessage is string => !!errorMessage);

  if (files.length > leastNbrOfAttachmentSpaceLeft) {
    errors.push('attachments.upload.error.fileCount');
  }

  return errors;
}
