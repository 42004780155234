import { useTranslate } from 'hooks/useTranslate';
import { RowSpaced, Stack } from 'components/layout';
import Text from '@ingka/text';
import Button from '@ingka/button';
import { Confirmation } from 'components/common/Confirmation/Confirmation';
import { ErrorAlert } from 'components/alerts';
import { useNCADetailsAttachmentsUpload } from './useNCADetailsAttachmentsUpload';
import { NCADetailsAttachmentsUploadProps } from './NCADetailsAttachmentsUpload.types';

export function NCADetailsAttachmentsUpload(props: NCADetailsAttachmentsUploadProps) {
  // Props
  const { ncas, many } = props;

  // State
  const t = useTranslate();
  const { setFiles, formControl, submit, isSubmitDisabled, isLoading } =
    useNCADetailsAttachmentsUpload(ncas, many);

  return (
    <RowSpaced>
      <Stack>
        {/* INPUT */}
        <input
          key={formControl.key}
          name="attachments.upload"
          data-testid="attachments.upload.input"
          type={formControl.type}
          accept={formControl.accept}
          multiple
          onChange={(e) => setFiles(Array.from(e.target.files || []))}
        />

        {/* DESCRIPTIONS */}
        <Text>{formControl.description}</Text>
        {formControl.error.map((error) => (
          <ErrorAlert title="" message={t(error)} />
        ))}
      </Stack>

      {/* SUBMIT */}
      <Stack>
        <Confirmation
          handleOnConfirmed={submit}
          triggerElement={
            <Button
              type="emphasised"
              disabled={isSubmitDisabled}
              loading={isLoading}
              data-testid="attachments.upload.submit"
            >
              {t('attachments.upload.submit')}
            </Button>
          }
          disabled={isSubmitDisabled}
        />
      </Stack>
    </RowSpaced>
  );
}
