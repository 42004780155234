import { useAppNotifications } from 'app/app.notifications';
import { useState } from 'react';
import { useTranslate } from './useTranslate';

export function handleDownloadedBlob(blob: Blob, fileName: string) {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function useDownloadFile(
  url: string,
  fileName: string,
  downloadFn: (url: string) => Promise<Blob>
) {
  const t = useTranslate();
  const appNotifications = useAppNotifications();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const download = async () => {
    setIsLoading(true);
    setError(false);
    try {
      downloadFn(url)
        .then((blob) => handleDownloadedBlob(blob, fileName))
        .catch(() => {
          setError(true);
          appNotifications.showErrorNotification(t('downloadFailed'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
    }
  };

  return { isLoading, error, download };
}
