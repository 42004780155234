import { configuredAxios } from './configuredAxios';
import { appConfig } from 'app/app.config';

export const attachmentsAPI = {
  upload: (nonConformityAdviceIds: string[], files: File[]): Promise<void> => {
    const formData = new FormData();

    files.forEach((file, index) => formData.append(`attachment_upload_${index}`, file));
    nonConformityAdviceIds.forEach((id, i) => formData.append(`nonConformityAdviceIds_${i}`, id));

    return configuredAxios.post(appConfig.attachmentsEndpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  download: (attachmentId: string): Promise<Blob> => {
    return configuredAxios
      .get(`${appConfig.attachmentsEndpoint}/${attachmentId}`, {
        responseType: 'blob',
      })
      .then((result) => result.data)
      .catch((error) => error);
  },
};
