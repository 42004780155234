import { UnexpectedError } from 'components/alerts';
import { AppLayout } from 'app/components/AppLayout/AppLayout';
import { Dashboard } from 'pages/dashboard';
import { NCAPage } from 'pages/nca';
import NotFound from 'pages/NotFound';
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';
import { LdmView } from 'pages/LdmView/LdmView';

export const appRoutes: RouteObject[] = [
  {
    element: <AppLayout />,
    children: [
      {
        path: '/',
        errorElement: <UnexpectedError />,
        element: <Dashboard />,
      },
      {
        path: '/nca/*',
        errorElement: <UnexpectedError />,
        element: <NCAPage />,
      },
      {
        path: '/ldm/*',
        errorElement: <UnexpectedError />,
        element: <LdmView />,
      },
      {
        path: '*',
        errorElement: <UnexpectedError />,
        element: <NotFound />,
      },
    ],
  },
];

const appRouter = createBrowserRouter(appRoutes);

export function AppRoutes() {
  return <RouterProvider router={appRouter} />;
}
