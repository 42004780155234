import Text from '@ingka/text';
import './AppNavigation.css';
import { NavigationExternalLinks } from './components/NavigationExternalLinks';
import { NavigationInternalLinks } from './components/NavigationInternalLinks';
import { useTranslate } from '../../../hooks/useTranslate';

export function AppNavigation() {
  const translate = useTranslate();

  return (
    <nav className="app-navigation">
      <NavigationInternalLinks />
      <hr />
      <Text className="app-navigation__heading">{translate('externalLinks')}</Text>
      <NavigationExternalLinks />
    </nav>
  );
}
