import { AssignmentRuleDto } from 'api/genTypes/dto';
import { Subsection } from 'components/common/Subsection';
import { DataTable } from 'components/DataTable/DataTable';
import { useTranslate } from 'hooks/useTranslate';
import Text from '@ingka/text';
import { Confirmation } from 'components/common/Confirmation/Confirmation';
import Button from '@ingka/button';
import { LdmViewAssignmentsEditModal } from './LdmViewAssignmentsEditModal';
import { Row, Stack, StackSpaced } from 'components/layout';
import { styled } from 'styled-components';
import { ErrorAlert, InfoAlert } from 'components/alerts';
import { useLdmViewAssignments } from './useLdmViewAssignments';
import { SelectedAssignment } from './ldmViewAssignments.types';

const RowButtons = styled(Stack)`
  justify-content: flex-end;
`;

export function LdmViewAssignments() {
  //Hooks
  const t = useTranslate();
  const {
    query,
    submit,
    rules,
    setRules,
    isSubmitting,
    errorMessage,
    successMessage,
    selectedRule,
    setSelectedRule,
    changedRules,
    setChangedRules,
    hasChanges,
    onDeleteRule,
    onEditRule,
    saveAddOrEditToTable,
  } = useLdmViewAssignments();

  const createEmptySelectedRule = () => {
    // Need to create newRule as const so AssignmentRuleDto types are applied
    const newRule: SelectedAssignment = {
      rule: {
        tsp: null,
        id: Math.max(...rules.map((x) => x.id), 1) + 1,
        flow: null,
        endRegion: null,
        endCountry: null,
        modeOfTransport: null,
        startCountry: null,
        userEmail: null,
        startRegion: null,
      },
      isNew: true,
    };
    return newRule;
  };

  return (
    <>
      {selectedRule && (
        <LdmViewAssignmentsEditModal
          assignmentRule={selectedRule}
          setAssignmentRule={setSelectedRule}
          onModalClose={() => setSelectedRule(null)}
          onSubmit={() =>
            saveAddOrEditToTable(
              rules,
              setRules,
              selectedRule,
              setSelectedRule,
              changedRules,
              setChangedRules
            )
          }
        />
      )}
      <Subsection header={<Text headingSize="s">{'Assignments'}</Text>}>
        <StackSpaced>
          {query.isFetching ? (
            <Text>{'Loading...'}</Text>
          ) : (
            <DataTable<AssignmentRuleDto>
              data={rules}
              columns={[
                {
                  columnId: 'userEmail',
                  columnType: 'string',
                },
                {
                  columnId: 'flow',
                  columnType: 'string',
                },
                {
                  columnId: 'modeOfTransport',
                  columnType: 'string',
                },
                {
                  columnId: 'tsp',
                  columnType: 'string',
                },
                {
                  columnId: 'startRegion',
                  columnType: 'string',
                },
                {
                  columnId: 'startCountry',
                  columnType: 'string',
                },
                {
                  columnId: 'endRegion',
                  columnType: 'string',
                },
                {
                  columnId: 'endCountry',
                  columnType: 'string',
                },
              ]}
              onEditRow={(id) => onEditRule(id, isSubmitting, rules, setSelectedRule)}
              onDeleteRow={(id) =>
                onDeleteRule(id, isSubmitting, rules, setRules, changedRules, setChangedRules)
              }
            />
          )}
          {successMessage && (
            <InfoAlert
              message={successMessage}
              title={successMessage}
              data-testid={'infoAlert-testId'}
            />
          )}
          {errorMessage && (
            <ErrorAlert
              message={errorMessage}
              title={t('assignmentsUpdateError')}
              data-testid={'errorAlert-testId'}
            />
          )}
          <RowButtons>
            <Row>
              <Button
                type="secondary"
                disabled={query.isFetching || selectedRule !== null || isSubmitting}
                onClick={() => {
                  setSelectedRule(createEmptySelectedRule());
                }}
                data-testid="addRuleButton"
              >
                {t('addNewAssignmentRule')}
              </Button>
              <Confirmation
                handleOnConfirmed={submit}
                triggerElement={
                  <Button
                    type="emphasised"
                    disabled={!hasChanges()}
                    loading={isSubmitting}
                    data-testid="submitRulesChangesButton"
                  >
                    {t('saveAndSubmit')}
                  </Button>
                }
                disabled={!hasChanges() || isSubmitting}
              />
            </Row>
          </RowButtons>
        </StackSpaced>
      </Subsection>
    </>
  );
}
