export interface DatetimeProps {
  date: Date | string;
  simple?: boolean;
}

function prependZero(value: number): string {
  return value < 10 ? `0${value}` : value.toString();
}

export function Datetime(props: DatetimeProps) {
  if (!props.date) {
    return null;
  }

  if (props.simple) {
    const date: Date = typeof props.date === 'string' ? new Date(props.date) : props.date;
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const timeZone = date.getTimezoneOffset() / 60;

    return (
      <time dateTime={date.toISOString()}>
        {year}-{prependZero(month)}-{prependZero(day)} {prependZero(hours)}:{prependZero(minutes)}{' '}
        (UTC
        {timeZone > 0 ? `+${timeZone}` : timeZone})
      </time>
    );
  }

  if (typeof props.date === 'string') {
    return <time dateTime={new Date(props.date).toISOString()}>{props.date}</time>;
  }

  return <time dateTime={props.date.toISOString()}>{props.date.toISOString()}</time>;
}
