import Modal, { ModalHeader, Theatre } from '@ingka/modal';
import { useTranslate } from 'hooks/useTranslate';
import Text from '@ingka/text';
import { styled } from 'styled-components';
import { Stack } from 'components/layout';
import InputField from '@ingka/input-field';
import Button from '@ingka/button';
import { useEffect, useState } from 'react';
import { SelectedAssignment } from './ldmViewAssignments.types';

const StyledModalStack = styled(Stack)`
  padding: 1rem;
`;

export function isRuleValid(rule: SelectedAssignment): boolean {
  // userEmail and at least some condition is required
  const hasUserEmail = !!rule.rule.userEmail;
  const hasAtLeastOneRuleDimension =
    rule.rule.tsp ||
    rule.rule.flow ||
    rule.rule.modeOfTransport ||
    rule.rule.startCountry ||
    rule.rule.startRegion ||
    rule.rule.endCountry ||
    rule.rule.endRegion;
  if (hasUserEmail && hasAtLeastOneRuleDimension) {
    return true;
  }
  return false;
}

export function LdmViewAssignmentsEditModal({
  assignmentRule,
  setAssignmentRule,
  onModalClose,
  onSubmit,
}: {
  assignmentRule: SelectedAssignment | null;
  setAssignmentRule: (rule: SelectedAssignment) => void;
  onModalClose: () => void;
  onSubmit: () => void;
}) {
  // default false to require a change to make valid
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const t = useTranslate();
  useEffect(() => {
    setIsValid(isRuleValid(assignmentRule));
  }, [assignmentRule]);

  return (
    <Modal
      handleCloseBtn={onModalClose}
      visible={assignmentRule !== null}
      data-testid={'ldmAssignmentRuleModal'}
      escapable
    >
      <Theatre
        header={
          <ModalHeader>
            <Text headingSize="l" tagName="h3">
              {assignmentRule.isNew
                ? t('ldmAssignmentsAddModalHeader')
                : t('ldmAssignmentsEditModalHeader')}
            </Text>
          </ModalHeader>
        }
      >
        <StyledModalStack>
          <InputField
            label={'User Email'}
            value={assignmentRule.rule.userEmail}
            onChange={(e) =>
              setAssignmentRule({
                rule: { ...assignmentRule.rule, userEmail: e.target.value },
                isNew: assignmentRule.isNew,
              })
            }
            id={'ldm-assignment-edit-user-email'}
            type={'email'}
          />
          <InputField
            label={'Flow'}
            value={assignmentRule.rule.flow}
            onChange={(e) =>
              setAssignmentRule({
                rule: { ...assignmentRule.rule, flow: e.target.value },
                isNew: assignmentRule.isNew,
              })
            }
            id={'ldm-assignment-edit-flow'}
            type={'text'}
          />
          <InputField
            label={'Mode of Transport'}
            value={assignmentRule.rule.modeOfTransport}
            onChange={(e) =>
              setAssignmentRule({
                rule: { ...assignmentRule.rule, modeOfTransport: e.target.value },
                isNew: assignmentRule.isNew,
              })
            }
            id={'ldm-assignment-edit-modeOfTransport'}
            type={'text'}
          />
          <InputField
            label={'TSP'}
            value={assignmentRule.rule.tsp}
            onChange={(e) =>
              setAssignmentRule({
                rule: { ...assignmentRule.rule, tsp: e.target.value },
                isNew: assignmentRule.isNew,
              })
            }
            id={'ldm-assignment-edit-tsp'}
            type={'text'}
          />
          <InputField
            label={'Start Region'}
            value={assignmentRule.rule.startRegion}
            onChange={(e) =>
              setAssignmentRule({
                rule: { ...assignmentRule.rule, startRegion: e.target.value },
                isNew: assignmentRule.isNew,
              })
            }
            id={'ldm-assignment-edit-startRegion'}
            type={'text'}
          />
          <InputField
            label={'Start Country'}
            value={assignmentRule.rule.startCountry}
            onChange={(e) =>
              setAssignmentRule({
                rule: { ...assignmentRule.rule, startCountry: e.target.value },
                isNew: assignmentRule.isNew,
              })
            }
            id={'ldm-assignment-edit-startCountry'}
            type={'text'}
          />
          <InputField
            label={'End Region'}
            value={assignmentRule.rule.endRegion}
            onChange={(e) =>
              setAssignmentRule({
                rule: { ...assignmentRule.rule, endRegion: e.target.value },
                isNew: assignmentRule.isNew,
              })
            }
            id={'ldm-assignment-edit-endRegion'}
            type={'text'}
          />
          <InputField
            label={'End Country'}
            value={assignmentRule.rule.endCountry}
            onChange={(e) =>
              setAssignmentRule({
                rule: { ...assignmentRule.rule, endCountry: e.target.value },
                isNew: assignmentRule.isNew,
              })
            }
            id={'ldm-assignment-edit-endCountry'}
            type={'text'}
          />
          <Button
            type="emphasised"
            disabled={!isValid}
            loading={isLoading}
            onClick={() => {
              setIsLoading(true);
              onSubmit();
              setIsLoading(false);
            }}
          >
            {assignmentRule.isNew ? t('addRule') : t('updateRule')}
          </Button>
        </StyledModalStack>
      </Theatre>
    </Modal>
  );
}
