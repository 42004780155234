import { useState, useEffect } from 'react';
import {
  DataTableEntity,
  DataTableProps,
  DataTableSelectionEventInternal,
  DataTableColumn,
  DataTableSelectedItems,
} from '../DataTable.types';
import {
  areAllVisibleItemsThatCanBeSelectedSelected,
  createNewSelectionWithAddedOrRemovedItems,
} from '../DataTable.utils';

export function useDataTable<T extends DataTableEntity>({
  columns,
  data,
  isLoading,
  error,
  idField,
  withSelecting,
  selectionValues,
  validateSelection,
  onSelect,
  onEditRow,
  onDeleteRow,
}: DataTableProps<T>) {
  // The local state of which rows are selected or not in the DataTable.
  const [selectedItems, setSelectedItems] = useState<DataTableSelectedItems<T>>(
    selectionValues || new Map<string, T>()
  );

  // Ensure that we update our local selecting state if the parent component changes the selectingState prop.
  useEffect(() => {
    if (selectionValues) {
      setSelectedItems(selectionValues);
    }
  }, [selectionValues]);

  /** Handle what happens if the user has clicked on the checkbox of a single row. */
  const handleOnSelect = (event: DataTableSelectionEventInternal<T>) => {
    const newSelection: DataTableSelectedItems<T> = createNewSelectionWithAddedOrRemovedItems(
      !!event.checked,
      selectedItems,
      [event.item],
      idField,
      validateSelection
    );

    setSelectedItems(newSelection);

    // Notify subscribers to onSelect if any exists
    if (onSelect) {
      onSelect({
        items: newSelection,
      });
    }
  };
  /** Handle what happens if the user clicks on the selection checkbox in the top (header) row. */
  const handleOnSelectAll = () => {
    // If we have no specified an id field, then we can't handle selection.
    if (!idField) {
      return;
    }

    const newSelection: DataTableSelectedItems<T> = createNewSelectionWithAddedOrRemovedItems(
      !areAllSelectableVisibleItemsSelected,
      // instantiate with the first item in the list to get the correct type
      selectedItems.size > 0
        ? selectedItems
        : new Map<string, T>([[visibleItems?.[0][idField], visibleItems?.[0]]]),
      visibleItems,
      idField,
      validateSelection
    );

    setSelectedItems(newSelection);

    if (onSelect) {
      onSelect({
        items: newSelection,
      });
    }
  };

  /** Only render table rows if:
   *
   * - We have data
   * - We are not currently loading data
   * - There are no errors
   */
  const shouldRenderTableRows: boolean = data && !isLoading && !error;

  // In case some data entries are undefined, we filter those away first.
  const visibleItems: T[] = data?.filter((d) => d) || [];

  // The following are the columns we render. By default, these are the columns from the props.
  const columnsToRender: DataTableColumn<T>[] = [...columns];

  // If we allow selecing of rows in the DataTable, then add a column for checkboxes to visually represent the selection mechanism.
  if (withSelecting) {
    columnsToRender.unshift({
      columnId: 'data-table-checkbox',
      columnType: 'checkbox',
    });
  }

  if (onEditRow) {
    columnsToRender.unshift({
      columnId: 'edit',
      columnType: 'edit',
    });
  }

  if (onDeleteRow) {
    columnsToRender.unshift({
      columnId: 'delete',
      columnType: 'delete',
    });
  }

  // TODO: When pagination is implemented, this logic will break and must be refactored.
  const areAllSelectableVisibleItemsSelected: boolean = areAllVisibleItemsThatCanBeSelectedSelected(
    visibleItems,
    selectedItems,
    validateSelection,
    idField
  );

  return {
    columnsToRender,
    shouldRenderTableRows,
    areAllSelectableVisibleItemsSelected,
    visibleItems,
    selectedItems,
    handleOnSelect,
    handleOnSelectAll,
  };
}
