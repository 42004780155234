import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { AppLogin } from 'app/components/AppLogin/AppLogin';

export function AppLayoutAuthentication({ children }: { children: React.ReactNode }) {
  return (
    <>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AppLogin />
      </UnauthenticatedTemplate>
    </>
  );
}
