import React from 'react';
import './AppLayoutContainer.css';

export interface AppLayoutContainerProps {
  children?: React.ReactNode;
}

export function AppLayoutContainer({ children }: AppLayoutContainerProps) {
  return <div className="app-layout-container">{children}</div>;
}
