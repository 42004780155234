import { Outlet } from 'react-router-dom';
import './AppLayout.css';
import { AppHeader } from '../AppHeader/AppHeader';
import { AppNavigation } from '../AppNavigation/AppNavigation';
import { AppLayoutContainer } from './components/AppLayoutContainer/AppLayoutContainer';
import { AppLayoutAuthentication } from './components/AppLayoutAuthentication/AppLayoutAuthentication';

export function AppLayout() {
  return (
    <AppLayoutAuthentication>
      <div className="app-layout">
        <AppHeader />
        <div className="app-layout__nav">
          <AppNavigation />
        </div>
        <main className="app-layout__main">
          <AppLayoutContainer>
            <Outlet />
          </AppLayoutContainer>
        </main>
      </div>
    </AppLayoutAuthentication>
  );
}
