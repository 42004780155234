export enum NcaStatuses {
  Open = 'Open',
  DataIncomplete = 'Data Incomplete',
  AdditionalInformationRequested = 'Additional Information Requested',
  Reopened = 'Re-opened',
  Dispute = 'Dispute',
  Rejected = 'Rejected',
  Accepted = 'Accepted',
  ManagerApprovalPending = 'Manager Approval Pending',
  Paid = 'Paid',
}

export const ActionableNCAStatuses: string[] = [
  NcaStatuses.Open,
  NcaStatuses.AdditionalInformationRequested,
  NcaStatuses.Reopened,
  NcaStatuses.ManagerApprovalPending,
];
