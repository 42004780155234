export enum UserRole {
  LDS = 'LDS',
  LDM = 'LDM',
  SUPER_USER = 'SUPER_USER',
}

export function hasUserRole(role: UserRole, currentRole: UserRole) {
  return role === currentRole;
}

export function getUserRolesValues(currentRole: UserRole) {
  return {
    currentRole,
    hasUserRole: (role: UserRole): boolean => hasUserRole(role, currentRole),
    isLDS: currentRole === UserRole.LDS,
    isLDM: currentRole === UserRole.LDM,
  };
}

export function useUserRoles() {
  // TODO: Implement fetching role. Trinary operator used to avoid TypeScript compiler warnings.
  const currentRole: UserRole = false ? UserRole.LDS : UserRole.LDM;
  const result = getUserRolesValues(currentRole);

  return result;
}
